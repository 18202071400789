import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Card, CardBody,  CardHeader,} from "../../../_metronic/_partials/controls";
import alertify from "alertifyjs";
import { getCityLists, createNewTerminal } from "../../../app/sagas/routeManagement";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';

export function CreateTerminal(props) {

  const [ loading, setLoading ] = useState(true);
  const { userId } = useSelector((state) => state.Auth.user);

  const name = useRef();
  const description = useRef();
  const latitude = useRef();
  const longitude = useRef();
  const city_id = useRef();
  const [ cities, setCities ] = useState([]);
  const [ image, setImage ] = useState('');

  useEffect(() => {
		const fetchData = async () => {
			try {
        const apiResponse = await getCityLists(0, 200);
        const { data = [], success } = apiResponse?.data;
        if (success) {
          setCities(data);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }	
		};
		fetchData();
	}, []);

  const handleImage = (e) =>{
    var reader = new FileReader(); 
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  const createTerminalHandler = async(e) => {
    e.preventDefault();
    if(name.current.value === "" || latitude.current.value === "" || longitude.current.value === ""){
      alertify.error("All fields are required");
    } else{
      setLoading(true);
      try {
        const apiResponse = await createNewTerminal( {
          "terminal":{
            "id": null,
            "uuid": null,
            "name": name.current.value,
            "description": description.current.value,
            "latitude": latitude.current.value,
            "longitude": longitude.current.value,
            "cityId": city_id.current.value,
            "image": image,
            "status": "true",
            "createdBy": userId,
            "updatedBy": userId,
            "deleted": false
          }
        }, userId);
        const { success, errors = [] } = apiResponse?.data;
        if(success){
          alertify.success("Terminal record added successfully");
          props.history.push("/terminal/list");
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }
    }    
  }

  return (
    loading ? <LoaderScreen />:
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Add New Terminal"/>
          <CardBody> 
            <div className="mt-5">
              <Form onSubmit={createTerminalHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control ref={name} placeholder="Enter name" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>City</Form.Label>
                    <Form.Control as="select" ref={city_id}>
                      <option key='' value=''>Select City</option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.uuid}>{city.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control ref={latitude} placeholder="Enter latitude" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control ref={longitude} placeholder="Enter longitude" />
                  </Form.Group>             
                </Form.Row>
                <Form.Row> 
                  <Form.Group as={Col}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" ref={description} placeholder="Enter description" />
                  </Form.Group>                 
                  <Form.Group as={Col}>
                    <Form.Label>Image</Form.Label>
                    <Row>
                      <Col>
                        <Form.Control type="file" onChange={handleImage} />
                      </Col>
                      <Col>
                        {image !== ''? <img width='100' src={image} alt="img"/> : ''}
                      </Col>
                    </Row>                    
                  </Form.Group>
                </Form.Row>
			          <Button variant="primary" className="float-right" type="submit"> Save</Button>
			        </Form>             
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}