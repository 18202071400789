/* AUTH_REFRESH */
export const REQUEST_REFRESH_JWT_TOKEN = "REQUEST_REFRESH_JWT_TOKEN";
export const RECEIVED_REFRESH_JWT_TOKEN = "RECEIVED_REFRESH_JWT_TOKEN";
export const FAILED_REFRESH_JWT_TOKEN = "FAILED_REFRESH_JWT_TOKEN";

export const ERROR_RECEIVED = "ERROR_RECEIVED";
export const ERROR_CANCELED = "ERROR_CANCELED";
export const LOADING_RECEIVED = "LOADING_RECEIVED";
export const LOADING_COMPLETED = "LOADING_COMPLETED";

/* Sign-in */
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const RECEIVED_LOGIN = "RECEIVED_LOGIN";
export const FAILED_LOGIN = "FAILED_LOGIN";

/* Change Password*/
export const REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD";
export const RECEIVED_CHANGE_PASSWORD = "RECEIVED_CHANGE_PASSWORD";
export const FAILED_CHANGE_PASSWORD = "FAILED_CHANGE_PASSWORD";
export const RESET_CHANGE_PASSWORD_STATE = "RESET_CHANGE_PASSWORD_STATE";

/* Reset Password */

export const REQUEST_DEFAULT_PASSWORD = "REQUEST_DEFAULT_PASSWORD";
export const RECEIVED_DEFAULT_PASSWORD = "RECEIVED_DEFAULT_PASSWORD";
export const FAILED_DEFAULT_PASSWORD = "FAILED_DEFAULT_PASSWORD";
export const CANCELED_DEFAULT_PASSWORD = "CANCELED_DEFAULT_PASSWORD";

export const REQUEST_SET_PASSWORD = "REQUEST_SET_PASSWORD";
export const RECEIVED_SET_PASSWORD = "RECEIVED__SET_PASSWORD";
export const FAILED_SET_PASSWORD = "FAILED_SET_PASSWORD";
export const CANCELED_SET_PASSWORD = "CANCELED_SET_PASSWORD";

export const REQUEST_GENERATE_PASSWORD = "REQUEST_GENERATE_PASSWORD";
export const RECEIVED_GENERATE_PASSWORD = "RECEIVED_GENERATE_PASSWORD";
export const FAILED_GENERATE_PASSWORD = "FAILED_GENERATE_PASSWORD";
export const CANCELED_GENERATE_PASSWORD = "CANCELED_GENERATE_PASSWORD";

export const DESTROY_SESSION = "DESTROY_SESSION";

/* City */
export const SAVE_CITY = "SAVE_CITY";
export const SAVE_CITY_SUCCESS = "SAVE_CITY_SUCCESS";
export const SAVE_CITY_FAILED = "SAVE_CITY_FAILED";

export const UPDATE_CITY = "UPDATE_CITY";
export const UPDATE_CITY_RECEIVED = "UPDATE_CITY_RECEIVED";
export const UPDATE_CITY_FAILED = "UPDATE_CITY_FAILED";

export const FETCH_CITY = "FETCH_CITY";
export const FETCH_CITY_SUCCESS = "FETCH_CITY_SUCCESS";
export const FETCH_CITY_FAILED = "FETCH_CITY_FAILED";

export const FETCH_CITY_LIST = "FETCH_CITY_LIST";
export const FETCH_CITY_LIST_SUCCESS = "FETCH_CITY_LIST_SUCCESS";
export const FETCH_CITY_LIST_FAILED = "FETCH_CITY_LIST_FAILED";

export const REMOVE_CITY = "REMOVE_CITY";
export const REMOVE_CITY_SUCCESS = "REMOVE_CITY_SUCCESS";
export const REMOVE_CITY_FAILED = "REMOVE_CITY_FAILED";