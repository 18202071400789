import React from "react";
import { Redirect, Route } from "react-router-dom";
import { NonAuthRoutes } from '../_helpers/routingManagement';
import { TOKEN, LOCAL_STORAGE_CUSTOMER_DATA_KEY } from "../app/pages/constants";

export function AuthRoutes({ Component, path, exact, requiredRoles  }) {  
    const storedToken = window.localStorage.getItem(TOKEN);
    const currentUser = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY));    
    const isAuthorized = storedToken || false;
    const userHasRequiredRole = requiredRoles.includes(currentUser.role);
	const message = userHasRequiredRole ? 'Please log in to view this page' : "You were never here, it's our secret!"
    return (
		<Route
			exact={exact}
			path={path}
			render={(props) =>
				isAuthorized && userHasRequiredRole ? (
					<Component {...props} />
				) : (
					<Redirect
                        to={{
                            pathname: userHasRequiredRole ? 
                            NonAuthRoutes.login : 
                            NonAuthRoutes.unauthorized,
                            state: { 
                                message,
                                requestedPath: path 
                            }
                        }}
					/>
				)
			}
		/>
	);
}