import React, { useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import { createNewBusType } from "../../../app/sagas/busManagement";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';

export function CreateBusType(props) {

  const [ loading, setLoading ] = useState(false);
  const { userId } = useSelector((state) => state.Auth.user);
  const name = useRef();
  const status = useRef();

  const createBusTypeHandler = async (e) => {
  	e.preventDefault();
    if(name.current.value === ""){
      alertify.error("All fields are required");
    } else{
      setLoading(true);
      try {
        const apiResponse = await createNewBusType( {
          "busType":{
            "id": null,
          "uuid": null,
          "name": name.current.value,
          "status": status.current.value,
          "createdBy": userId,
          "updatedBy": userId,
          "deleted": false
          }
        }, userId);
        const { success, errors = [] } = apiResponse?.data;
        if(success){
          alertify.success("Record added successfully");
          props.history.push("/bus-type/list");
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  }
  return (
    loading ? <LoaderScreen />:
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Add New Bus Type"/>
          <CardBody>          
            <div className="mt-5">
              <Form onSubmit={createBusTypeHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control ref={name} placeholder="Enter name" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" ref={status}>
                      <option value="true">Active</option>
                      <option value="false">In-Active</option>              
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Button variant="primary" className="float-right" type="submit">Save</Button>
              </Form>              
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}