import React, { } from "react";
import {AgentList} from "./AgentList";
import {Link} from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

export function Agents() {
  return (
  	<Card>
      <CardHeader title="Agent List">
        <CardHeaderToolbar>           
          <Link to="/agent/create" className="btn btn-primary">Add New</Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>        
        <div className="mt-5"><AgentList /></div>
      </CardBody>
    </Card>
  );
}