import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import { toAbsoluteUrl } from '../../../_helpers';
import SVG from "react-inlinesvg";
import { Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../../_metronic/_partials/controls";
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import { getCityLists, fetchRouteByCities, createNewRoute } from "../../../app/sagas/routeManagement";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

export function CreateRoute(props) {

  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const { userId } = useSelector((state) => state.Auth.user);

  const name = useRef("");
  const start_point = useRef("");
  const end_point = useRef("");
  const approximate_distance = useRef(0);
  const approximate_time_hours = useRef(0);
  const approximate_time_minutes = useRef(0);
  const departure_sequence_number = useRef(0);
  const arrival_sequence_number = useRef(0);
  const description = useRef();
  const status = useRef();
  const [startPoints, setStartPoints] = useState([]);
  const [endPoints, setEndPoints] = useState([]);
  const [subRoutes, setSubRoutes] = useState([]);
  const [routeExist, setRouteExist] = useState(false);

  const handleRowDel = (subRoute) =>{    
    var index = subRoutes.findIndex(obj => obj.ref === subRoute.ref);
    subRoutes.splice(index, 1);
    setSubRoutes([...subRoutes]);
  };

  useEffect(() => {
		const fetchData = async () => {
			try {
        const apiResponse = await getCityLists(0, 200);
        const { data = [], success } = apiResponse?.data;
        if (success) {
          setStartPoints(data);
          setEndPoints(data);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }	
		};
		fetchData();
	}, []);

  const handCreateSubRoute = () => {
    var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var subRoute = {
      id: null,
      uuid: id,
      name: "",
      distance: 0,
      departureSequenceNumber: 0,
      arrivalSequenceNumber: 0,
      fare: "0",  
      starting: "",
      ending: "",
      approximateTime: 0,
      isDefault: false,
      createdBy: userId,
      updatedBy: userId,
      deleted: false
    }
    subRoutes.push(subRoute);
    setSubRoutes([...subRoutes]);
  }
  const onNameChange = (ref, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === ref);
    subRoutes[index].name = data;
    setSubRoutes([...subRoutes]);
  }

  const onApproximateTimeChange = (ref, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === ref);
    subRoutes[index].approximateTime = data;
    setSubRoutes([...subRoutes]);
  }
  const onStartPointChange = (ref, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === ref);
    subRoutes[index].starting = data;
    setSubRoutes([...subRoutes]);
  }
  const onEndpointChange = (ref, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === ref);
    subRoutes[index].ending = data;
    setSubRoutes([...subRoutes]);        
  }
  const onIsDefaultChange = (ref, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === ref);
    subRoutes[index].isDefault = data;
    if(data){
      subRoutes[index].approximateTime = (parseInt((approximate_time_hours.current.value * 60 || 0)) + parseInt((approximate_time_minutes.current.value || 0)));
      subRoutes[index].distance = approximate_distance.current.value;
      subRoutes[index].name = name.current.value; 
      subRoutes[index].departureSequenceNumber = departure_sequence_number.current.value;
      subRoutes[index].arrivalSequenceNumber = arrival_sequence_number.current.value;
      subRoutes[index].starting = start_point.current.value;
      subRoutes[index].ending = end_point.current.value;
    }
    setSubRoutes([...subRoutes]);
  }

  const checkRoute = async()=>{
    if(start_point.current.value !== "" && end_point.current.value !== "" ){
      try {
        const apiResponse = await fetchRouteByCities(start_point.current.value, end_point.current.value);
        const { success } = apiResponse?.data;
        if (success) {
          alertify.error("This route exist");
          setRouteExist(true)
        } else{
          setRouteExist(false)
        }
      } catch (err) {
        setRouteExist(false);
      }
    }    
  }

  const onDistanceChange = (ref, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === ref);
    subRoutes[index].distance = data;
    setSubRoutes([...subRoutes]);
  }

  const createRouteHandler = async(e) => {
    e.preventDefault();
    if(name.current.value === "" || departure_sequence_number.current.value === "" || arrival_sequence_number.current.value === "" || approximate_distance.current.value === "" || start_point.current.value === ""|| end_point.current.value === "" || (subRoutes.length < 1)
      || (approximate_time_hours.current.value === "" && approximate_time_minutes.current.value === "")){
      alertify.error("All fields are required");
    } else if(start_point.current.value === end_point.current.value ){
      alertify.error("Start point and end point can not be the same");
    } else if(routeExist) {
      alertify.error("You can not create existing route");
    }else{
      setLoading(true);
      try {
        const apiResponse = await createNewRoute({
          "route":{
            "id": null,
            "uuid": null,
            "name": name.current.value,
            "departureSequenceNumber": departure_sequence_number.current.value,
            "arrivalSequenceNumber": arrival_sequence_number.current.value,
            "fare": 0.0,
            "distance": approximate_distance.current.value,
            "approximateTime": (parseInt((approximate_time_hours.current.value * 60) || 0) + parseInt(approximate_time_minutes.current.value || 0)),
            "description": description.current.value,
            "status": status.current.value,
            "createdBy": userId,
            "updatedBy": userId,
            "deleted": false,
            "startPoint": start_point.current.value,
            "endPoint": end_point.current.value
          },
          "subRoutes": subRoutes
        }, userId);
        const { success, errors = [] } = apiResponse?.data;
        if(success){
          alertify.success("Terminal record added successfully");
          props.history.push("/route/list");
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    loading ? <LoaderScreen />:
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Add New Route"/>
          <CardBody>        
            <div className="mt-5">
              <Form onSubmit={createRouteHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control ref={name} placeholder="Enter name" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" ref={status}>
                      <option value="true">Active</option>
                      <option value="false">In-Active</option>                    
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Start Point</Form.Label>
                    <Form.Control as="select" ref={start_point} onChange={checkRoute}>
                      <option key='' value=''>Select Start Point</option>
                      {startPoints.map((startPoint) => (
                        <option key={startPoint.uuid} value={startPoint.uuid}>{startPoint.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>End Point</Form.Label>
                    <Form.Control as="select" ref={end_point} onChange={checkRoute}>
                      <option key='' value=''>Select End Point</option>
                      {endPoints.map((endPoint) => (
                        <option key={endPoint.uuid} value={endPoint.uuid}>{endPoint.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Distance(KM)</Form.Label>
                    <Form.Control ref={approximate_distance} placeholder="Enter distance" type="number" min="0"/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Approximate Time(Hours)</Form.Label>
                        <Form.Control ref={approximate_time_hours} type="number" min="0"/>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Approximate Time(Minutes)</Form.Label>
                        <Form.Control ref={approximate_time_minutes} type="number" min="0"/>
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>                  
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Departure Sequence Number</Form.Label>
                    <Form.Control ref={departure_sequence_number} placeholder="Departure Sequence Number" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Arrival Sequence</Form.Label>
                    <Form.Control ref={arrival_sequence_number} placeholder="Enter Arrival Sequence Number" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" ref={description} placeholder="Enter description" />
                  </Form.Group>
                </Form.Row>
                <Card>
                  <CardHeader title="Sub Routes">
                    <CardHeaderToolbar>
                      <Button variant="primary" color="primary" className={classes.button} onClick={handCreateSubRoute}><Add /></Button>
                    </CardHeaderToolbar>
                  </CardHeader>
                  <CardBody>
                    <table id="sub_route_table" className="sharearide_datatable">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Start Point</th>
                          <th>End Point</th>
                          <th>Dist(Km)</th>
                          <th>Approx. Time(Mins)</th>
                          <th>Dep. Seq #</th>
                          <th>Arr. Seq #</th>
                          <th>Default?</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subRoutes.length > 0 ? (
                          subRoutes.map( (subRoute, index) => {
                            return (
                              <tr key={index}>
                                <td><input type='text' className='form-control' value={subRoute.name} onChange={(e) => onNameChange(subRoute.uuid, e.target.value)}/></td>
                                <td>
                                  <Form.Control as="select" className="starting" onChange={(e) => onStartPointChange(subRoute.uuid, e.target.value)} value={subRoute.starting}>
                                    <option key='' value=''>Select Start Point</option>
                                    {startPoints.map((startPoint, key) => (
                                      <option key={key} value={startPoint.uuid}>{startPoint.name}</option>
                                    ))}
                                  </Form.Control>
                                </td>
                                <td>
                                  <Form.Control as="select" className="ending" onChange={(e) => onEndpointChange(subRoute.uuid, e.target.value)} value={subRoute.ending}>
                                    <option key='' value=''>Select End Point</option>
                                    {startPoints.map((startPoint, key) => (
                                      <option key={key} value={startPoint.uuid}>{startPoint.name}</option>
                                    ))}
                                  </Form.Control>
                                </td>
                                <td><input type='number' min={0} className='form-control' placeholder='0' value={subRoute.distance}  onChange={(e) => onDistanceChange(subRoute.uuid, e.target.value)}/></td>
                                <td><input type='number' min={0} className='form-control' placeholder='0' value={subRoute.approximateTime}  onChange={(e) => onApproximateTimeChange(subRoute.uuid, e.target.value)}/></td>
                                <td><input type='number' min={0} className='form-control' placeholder='0' value={subRoute.departureSequenceNumber}  onChange={(e) => {
                                  var index = subRoutes.findIndex(obj => obj.uuid === subRoute.uuid);
                                  subRoutes[index].departureSequenceNumber = e.target.value;
                                  setSubRoutes([...subRoutes]);
                                }}/></td>
                                <td><input type='number' min={0} className='form-control' placeholder='0' value={subRoute.arrivalSequenceNumber}  onChange={(e) => {
                                  var index = subRoutes.findIndex(obj => obj.uuid === subRoute.uuid);
                                  subRoutes[index].arrivalSequenceNumber = e.target.value;
                                  setSubRoutes([...subRoutes]);
                                }}/></td>
                                
                                <td>
                                  <Form.Control as="select" className="isdefault" onChange={(e) => onIsDefaultChange(subRoute.uuid, e.target.value)} defaultValue={false}>
                                    <option key='1' value='true'>Yes</option>
                                    <option key='2' value='false'>No</option>
                                  </Form.Control>
                                </td>
                                <td>
                                  <a onClick={() => handleRowDel(subRoute)} key={index} className="btn btn-icon btn-light btn-hover-danger btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-danger"><SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} /></span>
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8} align="center">No Sub routes</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
                <Button variant="primary" className="float-right" type="submit">Save</Button>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}