import React, { useRef, useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import Select from 'react-select';
import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { getOperators, getOperator, createAgent, getUser } from "../../../app/sagas/userManagement";
import { getBusList } from "../../../app/sagas/busManagement";
import { LoaderScreen } from '../../modules/Loader';
export function CreateAgent(props) {
  
  const [loading, setLoading] = useState(true);
  const { userId, role } = useSelector((state) => state.Auth.user);
  const names = useRef();
  const email = useRef();
  const username = useRef();
  const password = useRef();
  const status = useRef();
  const partner = useRef();
  const [partners, setPartners] = useState([]);
  const [partnerInfo, setPartner] = useState({});
  const [buses, setBuses] = useState([]);
  const selectedBuses = useRef();
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState('');
  const [assignedBuses, assignBuses] = useState([]);
  const [busOperator, setBusOperator] = useState('');

  const onPartnerChange = async(ref) =>{
    buses.splice(0, buses.length);
    setBuses([...buses]);
    const apiResponse = await getBusList(`bus/all/${ref}/operator/0/200`);
		const { data = [], success } = apiResponse?.data;
		if (success) {
      for(var i = 0; i < data.length; i++){
        buses.push({
          label: data[i].Bus.regNo,
          value: data[i].Bus.uuid
        });
      }
			setBuses([...buses]);
		}
  }

  const onInputChange = (value, { action }) => {
    switch (action) {
      case 'input-change':
        setInputValue(value);
        return;
      case 'menu-close':
        let menuIsOpen = undefined;
        if (inputValue) {
          menuIsOpen = true;
        }
        setMenuIsOpen(menuIsOpen);
        return;
      default:
        return;
    }
  };

  const handleAssignedBusesChange = (e) => {
    assignedBuses.splice(0, assignedBuses.length);
    var options = e;
    if(options != undefined || options != null){
      for (var i = 0; i < options.length; i++) {
        assignedBuses.push(options[i]);
      }
    }
    assignBuses([...assignedBuses]);
  }

  useEffect(() => {
    const fetchData = async () => {
			try {
				if(role === "super"){
					const apiResponse = await getOperators(0, 500);
          const { data = [], success } = apiResponse?.data;
          if (success) {
            setPartners(data);
          }
				} else if(role === "admin"){
          const userResponse = await getUser(userId);
					if (userResponse?.data.success) {
						localStorage.setItem("busOperator", userResponse?.data.data.user.busOperator);
						const apiResponse = await getOperator(`${userResponse?.data.data.user.busOperator}`);
						if (apiResponse?.data.success) {
							setPartner(apiResponse?.data.data);
              setBusOperator(apiResponse?.data.data.uuid);
              buses.splice(0, buses.length);
              setBuses([...buses]);
              const response = await getBusList(`bus/all/${apiResponse?.data.data.uuid}/operator/0/200`);
              const { data = [], success, errors = [], message } = response?.data;
              if (success) {
                for(var i = 0; i < data.length; i++){
                  buses.push({
                    label: data[i].Bus.regNo,
                    value: data[i].Bus.uuid
                  });
                }
                setBuses([...buses]);
              }	else{
                alertify.error(errors[0]?.errorMsg || message);
              }
						}
					}			
				}
				
			} catch (error) {
				alertify.error(error.message);
			} finally {
        setLoading(false);
      }
		};
		fetchData();  
  }, [role, userId]);

  const createAgentHandler = async(e) => {
  	e.preventDefault();
    setLoading(true)
    try {
      const apiResponse = await createAgent( {
        "data": {
          "agent":{
            "id": null,
            "uuid": null,
            "name": names.current.value, 
            "image": null,
            "order": 0, 
            "status": status.current.value, 
            "createdBy": null,
            "updatedBy": null,
            "deleted": false,
            "busOperator": role === 'super' ? partner.current.value : partnerInfo.uuid
          },
          "login":{
            "username": username.current.value, 
            "email": email.current.value, 
            "password": password.current.value, 
          },
          "assignedBuses": assignedBuses
        }
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record added successfully");
        props.history.push("/agent/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);      
    } finally {
      setLoading(false);
      buses.splice(0, buses.length);
      setBuses([...buses]);
    }
  }
  return (
    loading ? <LoaderScreen /> :
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Add New Agent"/>
          <CardBody>          
            <div className="mt-5">
              <Form onSubmit={createAgentHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Names</Form.Label>
                    <Form.Control ref={names} placeholder="Enter full names" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control ref={email} placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Username</Form.Label>
                    <Form.Control ref={username} placeholder="Enter username" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>                  
                  <Form.Group as={Col}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" ref={password} placeholder="Enter password" />
                  </Form.Group>
                  {role === 'super' ?
                    <Form.Group as={Col}>
                      <Form.Label>Associated Partner</Form.Label>
                      <Form.Control as="select" ref={partner} onChange={(e) => onPartnerChange(e.target.value)}>
                        <option key='' value=''>Select Partner</option>
                        {partners.map((partner) => (
                          <option key={partner.uuid} value={partner.uuid}>
                            {partner.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>:
                    <Form.Group as={Col}>
                      <Form.Label>Associated Partner</Form.Label>
                      <Form.Control value={partnerInfo.name || ""}/>
                    </Form.Group>
                  }
                  <Form.Group as={Col}>
                    <Form.Label>Bus Assigned</Form.Label>
                    <Select ref={selectedBuses} onFocus={()=>setMenuIsOpen(true)} isMulti isClearable isSearchable onInputChange={onInputChange} name="buses" options={buses} menuIsOpen={menuIsOpen} onChange={handleAssignedBusesChange}/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>                  
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" ref={status}>
                      <option value="Active">Active</option>
                      <option value="Inactive">In-Active</option>                
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} />
                  <Form.Group as={Col} />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                  </Form.Group>
                </Form.Row>
                <Button variant="primary" className="float-right" type="submit">Save</Button>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}