import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import Select from 'react-select';
import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getBusList, createBusRoute } from "../../../app/sagas/busManagement";
import { getRouteLists, getSubRouteList } from "../../../app/sagas/routeManagement";
import { days } from "../constants";

export function CreateBusRoute(props) {
  
  const [loading, setLoading] = useState(true);
  const { userId } = useSelector((state) => state.Auth.user);
  const offset = 0;
	const pageLimit = 2000;
  const bus_id = useRef();
  const route_id = useRef();
  const departure = useRef();
  const arrival = useRef();
  const selectedDays = useRef();
  const [buses, setBuses] = useState([]);
  const [routesList, setRoutes] = useState([]);
  const [busSubRoutes, setBusSubRoutes] = useState([]);
  const [allowedDays, setAllowedDays] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState('');

  const handleDaysChange = (e) => {
    allowedDays.splice(0, allowedDays.length);
    setAllowedDays([...allowedDays]);
    var options = e;
    if(options != undefined || options != null){
      for (var i = 0; i < options.length; i++) {
        allowedDays.push(options[i].value);
      }
    }
    setAllowedDays([...allowedDays]);
  }

  const focus = () => {
    setMenuIsOpen(true);
  };

  const onInputChange = (value, { action }) => {
    switch (action) {
      case 'input-change':
        setInputValue(value);
        return;
      case 'menu-close':
        let menuIsOpen = undefined;
        if (inputValue) {
          menuIsOpen = true;
        }
        setMenuIsOpen(menuIsOpen);
        return;
      default:
        return;
    }
  };

  useEffect(() => {
		const fetchData = async () => {
      try {
        const busListApiResponse = await getBusList(`bus/all/${offset}/${pageLimit}`);
				if (busListApiResponse?.data.success) {
					setBuses(busListApiResponse?.data.data);
				}
				const apiResponse = await getRouteLists(offset, pageLimit);
        const { data = [], success } = apiResponse?.data;
        if (success) {
          setRoutes(data);
        }				
			} catch (error) {
				alertify.error(error.message);
			} finally{
        setLoading(false);
      }			
		};
		fetchData();
	}, [offset, pageLimit]);

  const onFareChange = (ref, data) =>{
    var index = busSubRoutes.findIndex(obj => obj.uuid == ref);
    busSubRoutes[index].fare = data;
    setBusSubRoutes([...busSubRoutes]);
  }
  const onDistanceChange = (ref, data) =>{
    var index = busSubRoutes.findIndex(obj => obj.uuid == ref);
    busSubRoutes[index].distance = data;
    setBusSubRoutes([...busSubRoutes]);
  }
  const onApproximateTimeChange = (ref, data) =>{
    var index = busSubRoutes.findIndex(obj => obj.uuid == ref);
    busSubRoutes[index].approximateTime = data;
    setBusSubRoutes([...busSubRoutes]);
  }

  const handleSelectRouteHandler = async(e) => {
    const busRouteId = e.target.value;
    busSubRoutes.splice(0, busSubRoutes.length);
    setBusSubRoutes([...busSubRoutes]);
    try {
      const apiResponse = await getSubRouteList(busRouteId);
      const { data = [], success } = apiResponse?.data;
      if (success) {
        for(var i = 0; i < data.length; i++){
          var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
          const busSubRoute = {
            "id": null,
            "uuid": id,
            "fare": 0,
            "distance": data[i].distance,
            "approximateTime": data[i].approximateTime,
            "status": true,
            "createdBy": userId,
            "updatedBy": userId,
            "deleted": false,
            "subRouteId": data[i].uuid,
            "subRouteName": data[i].name,
            "busRouteId": busRouteId
          }
          busSubRoutes.push(busSubRoute);
          setBusSubRoutes([...busSubRoutes]);
          $("#sub_route_table").DataTable();
        }
      }				
    } catch (error) {
      alertify.error(error.message);
    }
  }

  const createBusRouteHandler = async(e) => {
  	e.preventDefault();
    if(departure.current.value === "" || arrival.current.value === "" || bus_id.current.value === "" || route_id.current.value === "" || (allowedDays.length < 1)){
      alertify.error("All fields are required");
      return;
    }
    setLoading(true);
    try {
      const apiResponse = await createBusRoute({
        "busRoute":{
          "id": null,
          "uuid": null,
          "departure": departure.current.value,
          "arrival": arrival.current.value,
          "fare": 0,
          "status": true,  
          "allowedDays": "'" + allowedDays + "'", 
          "createdBy": userId,
          "updatedBy": userId,
          "deleted": false,
          "busId": bus_id.current.value,
          "routeId": route_id.current.value,
        },
        "busSubRoute": busSubRoutes
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record added successfully");
        props.history.push("/bus-route/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }    
  }
  return (
    loading ? <LoaderScreen /> :
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Add New Bus Route"/>
          <CardBody>
            <div className="mt-5">
              <Form onSubmit={createBusRouteHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Bus</Form.Label>
                    <Form.Control as="select" ref={bus_id}>
                      <option key='' value=''>Select Bus</option>
                      {buses.map((res) => (
                        <option key={res?.Bus.uuid} value={res?.Bus.uuid}>{res?.Bus.regNo}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Route</Form.Label>
                    <Form.Control as="select" ref={route_id} onChange={handleSelectRouteHandler}>
                      <option key='' value=''>Select Route</option>
                      {routesList.map((route) => (
                        <option key={route.uuid} value={route.uuid}>{route.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Departure Time</Form.Label>
                    <Form.Control type="time" ref={departure} />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Arrival Time</Form.Label>
                    <Form.Control type="time" ref={arrival} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Operational Days</Form.Label>
                    <Select ref={selectedDays} onFocus={focus} isMulti isClearable isSearchable onInputChange={onInputChange} name="days" 
                    options={days} menuIsOpen={menuIsOpen} onChange={handleDaysChange}/>              
                  </Form.Group>
                  <Form.Group as={Col}/>
                </Form.Row>
                <Card>
                  <CardHeader title="Sub Routes"/>
                  <CardBody>              
                    <table id="sub_route_table" className="sharearide_datatable">
                      <thead>
                        <tr>
                          <th>Sub Route</th>
                          <th>Fare</th>
                          <th>Distance (KM)</th>
                          <th>Approximate Time (Mins.)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {busSubRoutes.length > 0 ? (
                          busSubRoutes.map( (subRoute) => {
                            return (
                              <tr key={subRoute.uuid}>
                                <td><Form.Label>{subRoute.subRouteName}</Form.Label></td>
                                <td><Form.Control className="fare" type="number" min={0} step="0.01" placeholder="Fare" defaultValue={subRoute.fare} onChange={(e) => onFareChange(subRoute.uuid, e.target.value)}/></td>
                                <td><Form.Control className="distance" style={{background: '#D3D3D3'}} placeholder="Distance" defaultValue={subRoute.distance} onChange={(e) => onDistanceChange(subRoute.uuid, e.target.value)} readOnly={true}/></td>
                                <td><Form.Control className="distance" style={{background: '#D3D3D3'}} placeholder="Approximate Time" defaultValue={subRoute.approximateTime} onChange={(e) => onApproximateTimeChange(subRoute.uuid, e.target.value)} readOnly={true}/></td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={4} align="center">No Sub routes</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
                <Button variant="primary" className="float-right" type="submit">Save</Button>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}