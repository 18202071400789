import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {API_URL, busManagement, user, transaction, routes} from '../constants.js';
import Badge from 'react-bootstrap/Badge';
import Select from 'react-select';
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';

export function BookingList(props){

	const style = {
		label: {
		  fontSize: '.75rem',
		  fontWeight: 'bold',
		  lineHeight: 2,
		},
	};

	const bus = props;
	const [bookings, setBookings] = useState([]);
	const [booking, setBooking] = useState(null);
	const [show, setShow] = useState(false);
	const history = useHistory()
	const { userId, role } = useSelector((state) => state.Auth.user);
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(2000);
	const [loading, setLoading] = useState(true);
	
	const [assignedBuses, assignBuses] = useState([]);
  	const [defaultBus, setDefaultBus] = useState([]);

	const setCurrentBooking = (booking) => {
	    setBooking(booking);
	    setShow(true);
	};

  	const handleClose = () => setShow(false);

  	const deleteBooking = () => {
  		axios.delete(`${API_URL}${transaction}/bookings/${booking.uuid}`).then((response) => {
			const { success, errors = [], data, message } = response?.data;
			if(success){
	  			history.go(0);
	  			setShow(false);
	  			alertify.success("Record deleted successfully");
	  		}else {
		        alertify.error("Failed to remove specified record, internal error may have occurred");
		        return null;
		    }
  		});
  	}

	useEffect( () => {
		try{
			if(role === "super"){
				axios.get(`${API_URL}${busManagement}/all/${offset}/${limit}/bookings`).then( (response) => {
					const { success, data} = response?.data;
					if(success){	
						setBookings(data);
					}
				});
			}			
		} catch{

		}
	}, [role, offset, limit]);

	useEffect( () => {
		try{
			if(role === "admin"){
				axios.get(`${API_URL}/app/v1/user/${userId}`).then( (response) => {
					const { success, } = response?.data;
					if(success){
						var ref = response?.data.data.user.busOperator;
						axios.get(`${API_URL}${busManagement}/all/${ref}/${offset}/${limit}/bookings`).then( (response) => {
							const { success, data} = response?.data;
							if(success){	
								setBookings(data);
							}
						});
					}
				});				
			}
			if(role === "agent"){
				axios.get(`${API_URL}${busManagement}/agent/${userId}`).then( (response) => {
					const { success, data } = response?.data;
					if(success){
						for(var i = 0; i < data.associatedBuses.length; i++){
							assignedBuses.push({
								label: data.associatedBuses[i].bus.regNo,
								value: data.associatedBuses[i].bus.uuid
							});
						}
						assignBuses([...assignedBuses]);
						setDefaultBus({
							label: data.associatedBuses[0].bus.regNo,
							value: data.associatedBuses[0].bus.uuid
						});
						setBookings(data.associatedBuses[0].bookings);	
					}
				});					
			}
		} catch{

		}
	}, [role, userId, offset, limit]);
	useEffect( () => {		
		if(bookings.length > 0){
			$("#booking_table").DataTable();
		}
		setLoading(false);
	}, [bookings]);

	const handleChange = (option) => {
		setDefaultBus(option);
		try{
			axios.get(`${API_URL}${transaction}/bookings/all/bus/${option.value}/${offset}/${limit}`).then( (response) => {
				const { success, data } = response?.data;
				if(success){
					setBookings(data.data);
					$("#booking_table").DataTable();
				}
			});
		}catch(err){

		}
	};
	return (
		loading ? <LoaderScreen /> :
		<>
		<div className="row" style={{marginTop: -100, marginBottom: 10}}>
          	<div className="col-xl-6"></div>          	
			<div className="col-xl-1">
				{role === "agent" ? 
					<label style={style.label} style={{marginRight: 0, marginTop: 10}} > Select Bus </label> 
					: ""
				}             
			</div>
				
			<div className="col-xl-3" style={{marginTop: 10}}>
				{role === "agent" ?
					<form>
						<Select aria-labelledby="aria-label" inputId="aria-bus-input" name="aria-live-color" options={assignedBuses} isSearchable onChange={handleChange} value={defaultBus}/>
					</form>
					:
					""				}
			</div>				
          <div className="col-xl-2" style={{marginTop: 10}}>
          <Link to="/booking/create" className="btn btn-primary">Add New</Link>
          </div>
        </div>
		<table id="booking_table" className="sharearide_datatable">
		    <thead>
		      <tr>
		        <th>Res. #</th>
				<th>Date</th>
		        <th>First Name</th>
		        <th>Last Name</th>
				<th>Bus</th>
		        <th>Route</th>
		        <th>Seats</th>
		        <th>Fare</th>
				<th>Status</th>
		        <th>Actions</th>
		      </tr>
		    </thead>
		    <tbody>				
				{ bookings?.length > 0 ? (
					bookings.map((booking, index) => (
						<tr key={booking.uuid}>
								<td>{booking.reservationNumber}</td>
								<td>{booking.bookingDate}</td>
								<td>{booking.User.fname}</td>
								<td>{booking.User.lname}</td>
								<td>{booking.Bus.regNo}</td>
								<td>{booking.SubRoute.name}</td>
								<td>{booking.seats.slice(1,-1)}</td>
								<td>{booking.fare}</td>
								<td>{booking.isPaid !== "Yes" ? <Badge variant="warning">Pending</Badge> : <Badge variant="primary">Paid</Badge>}</td>
								<td>
									<Link to={{pathname:`/booking/detail/${booking.uuid}`, state: JSON.stringify(booking) }} className="btn btn-icon btn-light btn-hover-success  btn-sm mx-3">
										<span className="svg-icon svg-icon-md svg-icon-success ">
										<SVG
											src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
										/>
										</span>
									</Link>								
									<a onClick={() => setCurrentBooking(booking)} key={index} className="btn btn-icon btn-light btn-hover-danger btn-sm">
										<span className="svg-icon svg-icon-md svg-icon-danger">
											<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
										</span>
									</a>								
								</td>						
							</tr>
					))
				) : (
					<tr>
						<td colSpan={3}>No Booking</td>
					</tr>
				)}
		    </tbody>
		  </table>
		  <Modal show={show} onHide={handleClose}>
	        <Modal.Header closeButton>
	          <Modal.Title>Delete Booking</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>Are you sure you want to Delete?</Modal.Body>
	        <Modal.Footer>
	          <Button variant="secondary" onClick={handleClose}>
	            Cancel
	          </Button>
	          <Button variant="primary" onClick={deleteBooking}>
	            Yes
	          </Button>
	        </Modal.Footer>
	      </Modal>
	    </>
	)
}