import React, {useEffect, useState} from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import { LoaderScreen } from '../../modules/Loader';
import { getRouteLists, removeRouteDetails } from "../../../app/sagas/routeManagement";
import { connect } from "react-redux";
import { LOCAL_STORAGE_CUSTOMER_DATA_KEY } from "../constants";

const RouteList = ({
	user,
}) => {

	const currentUser = user ? user : {};
	const [loading, setLoading] = useState(true);
	const [deleting, setDeleting] = useState(false);
	const [routesList, setRoutes] = useState([]);
	const [route, setRoute] = useState(null);
	const [show, setShow] = useState(false);
	const history = useHistory()
	const [offset, setOffset] = useState(0);
	const [pageLimit, setPageLimit] = useState(2000);
	const [totalPages, setTotalPages] = useState(null);	

	useEffect(() => {
		const fetchData = async () => {
			const apiResponse = await getRouteLists(offset, pageLimit);
			const { data = [], success } = apiResponse?.data;
			if (success) {
				setRoutes(data);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		if(routesList.length > 0){
			$("#route_table").DataTable({responsive: true, stateSave: true});			
			setTotalPages(routesList.length);
			setOffset(routesList.length + 1);
			setPageLimit(routesList.length + 100);
		}
		setLoading(false);
	}, [routesList]);

	const setCurrentRoute = (id) => {
	    setRoute(id);
	    setShow(true);
	};

  	const deleteRoute = async() => {
		try {
			setDeleting(true);
			const apiResponse = await removeRouteDetails(route, currentUser.userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
			  alertify.success("Record removed successfully");
			  history.go(0);
			} else{
			  alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setShow(false);
			setDeleting(false);
		}
  	}

	return (
		loading ? <LoaderScreen /> :
		<>
			<table id="route_table" className="sharearide_datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Start Point</th>
						<th>End Point</th>
						<th>Distance</th>
						<th>Approx. Time</th>
						<th>Departure S.No</th>
						<th>Arrival S.No</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{routesList.length > 0 ? (
						routesList.map((route, index) => (
							<tr key={route.id}>
								<td>{route.id}</td>
								<td>{route.name}</td>
								<td>{route.startPoint}</td>
								<td>{route.endPoint}</td>
								<td>{route.distance}</td>
								<td>{route.approximateTime}</td>
								<td>{route.departureSequenceNumber}</td>
								<td>{route.arrivalSequenceNumber}</td>
								<td>
									<Link to={`/route/edit/${route.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
										<span className="svg-icon svg-icon-md svg-icon-primary"><SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/></span>
									</Link>
									<a onClick={() => setCurrentRoute(route.uuid)} key={index} className="btn btn-icon btn-light btn-hover-danger btn-sm">
										<span className="svg-icon svg-icon-md svg-icon-danger"><SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} /></span>
									</a>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={3}>No Route</td>
						</tr>
					)}
				</tbody>
			</table>
		  <Modal show={show} onHide={() => setShow(false)}>
	        <Modal.Header closeButton>
	          <Modal.Title>Delete Route</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>Are you sure you want to Delete?</Modal.Body>
	        <Modal.Footer>
	          	<Button variant="secondary" onClick={() => setShow(false)} disabled={ deleting }>Cancel</Button>
	          	<Button variant="primary" onClick={deleteRoute} disabled={ deleting }>
				  <span>Yes</span>{deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
				</Button>
	        </Modal.Footer>
	      </Modal>
	    </>
	)
}

function mapStateToProps(state) {
	return {
	  user: state?.Auth?.user || window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY),
	}
}
  
export default connect(mapStateToProps, null)(RouteList);