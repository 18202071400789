import axios from "../../config/axiosConfig";
import { formatRequest, } from "./common";

const { REACT_APP_API_VERSION } = process.env;

export function createNewBusType(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-type/create/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getBusTypeLists(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-type/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function getBusTypeDetails(busType) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-type/${busType}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateBusTypeDetails(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-type/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}
export function removeBusTypeDetails(busType, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-type/${userId}/${busType}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}

export function createNewBusFacility(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-facility/create/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getBusFacilityLists(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-facility/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function getBusFacilityDetails(busFacility) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-facility/${busFacility}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateBusFacilityDetails(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-facility/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}
export function removeBusFacilityDetails(busFacility, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/bus-facility/${userId}/${busFacility}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}

export function createBus(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/create/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getBusList(base) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/${base}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function removeBus(bus, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/${userId}/${bus}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}
export function getBus(bus) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/${bus}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateBus(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}

export function getBusRoutes(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/routes/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function createBusRoute(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/routes/create/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function updateBusRoute(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/routes/${userId}/edit`,
      "put",
      data,
      true,
      true,
    )
  );
}
export function removeBusRoute(busRoute, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/routes/${userId}/${busRoute}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}
export function getBusRoute(busRoute) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/routes/${busRoute}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function getBusSubRoutes(busRoute) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/routes/sub-route/bus-route/${busRoute}`,
      "get",
      null,
      true,
      true
    )
  );
}