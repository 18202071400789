import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import {Link} from "react-router-dom";

export function BaseTablesWidget6({ className, data }) {
  const tabs = {
    tab1: "daily",
    tab2: "weekly",
    tab3: "monthly",
  };
  const [activeTab, setActiveTab] = useState(tabs.tab1);
  const [dashboardData, setDashboardData] = useState({});
  
  useEffect(() => {
    setDashboardData(data);
  }, [data]);

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Bookings
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              {dashboardData.totalBookings > 500 ? "More than 500+ " : dashboardData?.totalBookings} total bookings
            </span>
          </h3>
          <div className="card-toolbar">
            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
            <li className="nav-item">
                <a className={`nav-link py-2 px-4 ${activeTab === tabs.tab1 && "active"}`} data-toggle="tab" href={`#${tabs.tab1}`} onClick={() => setActiveTab(tabs.tab1)}> Today </a>
              </li>              
              <li className="nav-item">
                <a className={`nav-link py-2 px-4 ${activeTab === tabs.tab2 && "active"}`} data-toggle="tab" href={`#${tabs.tab2}`} onClick={() => setActiveTab(tabs.tab2)}> This Week </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link py-2 px-4 ${activeTab === tabs.tab3 && "active"}`} data-toggle="tab" href={`#${tabs.tab3}`} onClick={() => setActiveTab(tabs.tab3)}> This Month </a>
              </li>              
            </ul>
          </div>
        </div>
        <div className="card-body pt-2 pb-0">
          <div className="table-responsive">
            <table id="booking_table" className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th className="p-0" style={{ width: "50px" }} />
                  <th className="p-0" style={{ minWidth: "150px" }} />
                  <th className="p-0" style={{ minWidth: "120px" }} />
                  <th className="p-0" style={{ minWidth: "70px" }} />
                  <th className="p-0" style={{ minWidth: "70px" }} />
                  <th className="p-0" style={{ minWidth: "50px" }} />
                </tr>
              </thead>
              <tbody>
              {activeTab === tabs.tab1 &&
                (dashboardData?.dailyBookings?.length > 0 ? (
                  dashboardData?.dailyBookings.map((booking, index) => (
                    <tr key={index}>
                      <td className="pl-0">
                        <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                          <span className="symbol-label"> 
                            {booking?.user?.image? <img width='100' src={booking.user.image} alt=""/> : <SVG className="h-75 align-self-end" src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}></SVG> }
                          </span>
                        </div>
                      </td>
                      <td className="pl-0">
                        <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" > {booking?.user?.fname + " " + booking?.user?.lname} </a>
                        <span className="text-muted font-weight-bold d-block"> Passengers covered: {booking?.totalPassengers} </span>
                      </td>
                      <td></td>
                      <td className="text-right">
                        <span className="text-muted font-weight-bold d-block font-size-sm"> {booking?.booking?.isPaid === "Yes" ? "Paid" : "Pending"} </span>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg"> BWP {booking?.booking?.ticketPrice} </span>
                      </td>
                      <td className="text-right">
                        {booking?.booking?.status === "active" ?
                          <span className="font-weight-bolder text-primary">Active</span>
                          : (
                            booking?.booking?.status === "used" ?
                              <span className="font-weight-bolder text-muted">Used</span>:
                              <span className="font-weight-bolder text-danger">Cancelled</span>
                          )
                        }
                      </td>
                      <td className="text-right pr-0">
                        <Link to={`/booking/detail/${booking?.booking?.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                          <span className="svg-icon svg-icon-md svg-icon-success"> 
                            <SVG src={toAbsoluteUrl( "/media/svg/icons/Navigation/Arrow-right.svg" )} ></SVG> 
                          </span>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} align="center"><span className="text-muted font-weight-bold d-block">No bookings today</span></td>
                  </tr>
                )
              )}
              {activeTab === tabs.tab2 &&
                (dashboardData?.weeklyBookings?.length > 0 ? (
                  dashboardData?.weeklyBookings.map((booking, index) => (
                    <tr key={index}>
                      <td className="pl-0">
                        <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                          <span className="symbol-label"> 
                            {booking?.user?.image? <img width='100' src={booking?.user?.image} alt=""/> : <SVG className="h-75 align-self-end" src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}></SVG> }
                          </span>
                        </div>
                      </td>
                      <td className="pl-0">
                        <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" > {booking?.user?.fname + " " + booking?.user?.lname} </a>
                        <span className="text-muted font-weight-bold d-block"> Passengers covered: {booking?.totalPassengers} </span>
                      </td>
                      <td></td>
                      <td className="text-right">
                        <span className="text-muted font-weight-bold d-block font-size-sm"> {booking?.booking?.isPaid === "Yes" ? "Paid" : "Pending"} </span>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg"> BWP {booking?.booking?.ticketPrice} </span>
                      </td>
                      <td className="text-right">
                        {booking.booking.status === "active" ?
                          <span className="font-weight-bolder text-primary">Active</span>
                          : (
                            booking.booking.status === "used" ?
                              <span className="font-weight-bolder text-muted">Used</span>:
                              <span className="font-weight-bolder text-danger">Cancelled</span>
                          )
                        }
                      </td>
                      <td className="text-right pr-0">
                        <Link to={`/booking/detail/${booking?.booking?.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                          <span className="svg-icon svg-icon-md svg-icon-success"> 
                            <SVG src={toAbsoluteUrl( "/media/svg/icons/Navigation/Arrow-right.svg" )} ></SVG> 
                          </span>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} align="center"><span className="text-muted font-weight-bold d-block">No bookings this week</span></td>
                  </tr>
                ))
              }
              {activeTab === tabs.tab3 &&
                (dashboardData?.monthlyBookings?.length > 0 ? (
                    dashboardData?.monthlyBookings.map((booking, index) => (
                      <tr key={index}>
                        <td className="pl-0">
                          <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                            <span className="symbol-label"> 
                              {booking?.user?.image? <img width='100' src={booking?.user?.image} alt=""/> : <SVG className="h-75 align-self-end" src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}></SVG> }
                            </span>
                          </div>
                        </td>
                        <td className="pl-0">
                          <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" > {booking?.user?.fname + " " + booking?.user?.lname} </a>
                          <span className="text-muted font-weight-bold d-block"> Passengers covered: {booking?.totalPassengers} </span>
                        </td>
                        <td></td>
                        <td className="text-right">
                          <span className="text-muted font-weight-bold d-block font-size-sm"> {booking?.booking?.isPaid === "Yes" ? "Paid" : "Pending"} </span>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg"> BWP {booking?.booking?.ticketPrice} </span>
                        </td>
                        <td className="text-right">
                          {booking.booking.status === "active" ?
                            <span className="font-weight-bolder text-primary">Active</span>
                            : (
                              booking.booking.status === "used" ?
                                <span className="font-weight-bolder text-muted">Used</span>:
                                <span className="font-weight-bolder text-danger">Cancelled</span>
                            )
                          }
                        </td>
                        <td className="text-right pr-0">
                          <Link to={`/booking/detail/${booking?.booking?.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                            <span className="svg-icon svg-icon-md svg-icon-success"> 
                              <SVG src={toAbsoluteUrl( "/media/svg/icons/Navigation/Arrow-right.svg" )} ></SVG> 
                            </span>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} align="center"><span className="text-muted font-weight-bold d-block">No bookings this month</span></td>
                    </tr>
                  ))
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
