import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { MixedWidget4, BaseTablesWidget6, AdvanceTablesWidget9,} from "../widgets";
import Spinner from 'react-bootstrap/Spinner';
import {API_URL, busManagement} from '../../../app/pages/constants.js';
import alertify from "alertifyjs";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { fetchDashboardData } from "../../../app/sagas/global";

export function AgentDashboard() {

  const style = {
    label: {
      fontSize: '.75rem',
      fontWeight: 'bold',
      lineHeight: 2,
    },
  };
  const data = {
    dailyTickets: 0, 
    weeklyTickets: 0, 
    monthlyTickets: 0, 
    annualTickets: 0, 
    result: { 
      numberOfTickets: [0],
      revenue: [0]
    },
    bookingStatistics:{
      dailyBookings: [{}],
      weeklyBookings: [{}],
      monthlyBookings: [{}],
      totalBookings: 0
    }
  }
  const { userId, role } = useSelector((state) => state.Auth.user);
	const [dashboardData, setDashboardData] = useState(data);
  const [loaded, setLoaded] = useState(true);
  const [defaultBus, setDefaultBus] = useState([]);
  const [assignedBuses, assignBuses] = useState([]);
  
  const handleChange = (option) => {
    setDefaultBus(option);
    setLoaded(true);
    try{
      axios.get(`${API_URL}/v2/app/global/${option.value}/bus`).then( (response) => {
        const { success, data} = response?.data;
        if(success){
          setDashboardData({
            annualTickets: data.annualTickets,
            dailyTickets: data.dailyTickets,
            monthlyTickets: data.monthlyTickets,
            weeklyTickets: data.weeklyTickets,
            result:{
              numberOfTickets: data.annualStatistics.allTickets,
              revenue: data.annualStatistics.allTicketsRevenue
            },
            bookingStatistics:{
              dailyBookings: data.bookingStatistics.dailyBookings,
              weeklyBookings: data.bookingStatistics.weeklyBookings,
              monthlyBookings: data.bookingStatistics.monthlyBookings,
              totalBookings: data.annualTickets,
            }
          });
        }	     
      });
    } catch (error) {
      alertify.error(error.message);
    } finally {
			setLoaded(false);
		} 
  };

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await fetchDashboardData(`${userId}/agent`);				
        const { success, data} = response?.data;
        if(success){
          setDashboardData({
            annualTickets: data.annualTickets,
            dailyTickets: data.dailyTickets,
            monthlyTickets: data.monthlyTickets,
            weeklyTickets: data.weeklyTickets,
            result:{
              numberOfTickets: data.annualStatistics.allTickets,
              revenue: data.annualStatistics.allTicketsRevenue
            },
            bookingStatistics:{
              dailyBookings: data.bookingStatistics.dailyBookings,
              weeklyBookings: data.bookingStatistics.weeklyBookings,
              monthlyBookings: data.bookingStatistics.monthlyBookings,
              totalBookings: data.annualTickets,
            }
          });
          setDefaultBus({
            label: data.defaultBus.regNo,
            value: data.defaultBus.uuid
          });        
        }
      } catch (error) {
        alertify.error(error.message);
      }
    }
		fetchData();
	}, [userId]);

  useEffect(() => {
    try{
      axios.get(`${API_URL}${busManagement}/agent/${userId}`).then( (response) => {
        const { success, data } = response?.data;
        if(success){
          for(var i = 0; i < data.associatedBuses.length; i++){
            assignedBuses.push({
              label: data.associatedBuses[i].bus.regNo,
              value: data.associatedBuses[i].bus.uuid
            });
          }
          assignBuses([...assignedBuses]);          
        }
      });
    } catch (error) {
      alertify.error(error.message);
    } finally {
			setLoaded(false);
		}    
	}, [userId]); 

  return (
    loaded ? 
      <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
        <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
          <Spinner animation="grow" variant='warning' />
        </div>
      </div>:
      <>
        <div className="row">
          <div className="col-xl-8"></div>
          <div className="col-xl-4">
            <form>
              <label style={style.label} id="aria-label" htmlFor="aria-bus-input"> Select Bus </label>
              <Select aria-labelledby="aria-label" inputId="aria-bus-input" name="aria-live-color" options={assignedBuses} isSearchable onChange={handleChange} value={defaultBus}/>
            </form>
            <br />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4">
            <MixedWidget4 className="gutter-b card-stretch" data={dashboardData} />
          </div>
          <div className="col-xl-8">
            <BaseTablesWidget6 className="gutter-b" data={dashboardData.bookingStatistics}/>
          </div>
        </div>
    </>
  );
}
