import React, { useEffect, useState, useRef } from "react";
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import {Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { getCityDetails, updateCityDetails } from "../../../app/sagas/routeManagement";

export function EditCity(props) {

  const { userId } = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(true);
	const [cityObject, setCity] = useState({});
  const name = useRef();
  const short_name = useRef();
  const id = props.match.params.id;

  useEffect(() => {    
    const fetchData = async () => {
      try {
        const apiResponse = await getCityDetails( id );
        const { success, data = {}, errors = [] } = apiResponse?.data;
        if(success){
          setCity(data);
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }
		};
		fetchData();
	}, []);

  const editCityHandler = async(e) => {
  	e.preventDefault();
    setLoading(true);
    try {
      const apiResponse = await updateCityDetails( {
        "city":{
          "id": cityObject.id,
          "uuid": cityObject.uuid,
          "name": name.current.value,
          "shortName": short_name.current.value,
          "order": cityObject.order,
          "status": cityObject.status,
          "createdBy": cityObject.createdBy,
          "updatedBy": cityObject.updatedBy,
         "deleted": cityObject.deleted
        }
      }, userId);
      const { success, data = {}, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record updated successfully");
        props.history.push("/city/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    loading ?
      <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
        <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
          <Spinner animation="grow" variant='warning' />
        </div>
      </div>: 
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Edit City"/>
          <CardBody>        
            <div className="mt-5">
              <Form onSubmit={editCityHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control ref={name} defaultValue={cityObject.name} placeholder="Enter name" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Short Name</Form.Label>
                    <Form.Control ref={short_name} defaultValue={cityObject.shortName} placeholder="Enter short name" />
                  </Form.Group>
                </Form.Row>
			          <Button variant="primary" className="float-right" type="submit">Save </Button>
			        </Form>             
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}