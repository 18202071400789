import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

import { makeStyles } from '@material-ui/core/styles';
import Spinner from 'react-bootstrap/Spinner'
import {API_URL, faq} from '../constants.js';
import { handleResponse } from '../../../_helpers';
import { useSelector } from "react-redux";

export function EditFaq(props) {
  const [loading, setLoading] = useState(false);
	const { userId, role } = useSelector((state) => state.Auth.user);
	const initialState = {
		faq: '',
		status: '',
	};

	const [faqInfo, setFaq] = useState({});
	const [fields, setFields] = useState(initialState);
  const title = useRef();
  const description = useRef();
  const order = useRef();
  const status = useRef();
  const id = props.match.params.id;

  const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

  useEffect(() => {
    axios.get(`${API_URL}${faq}/${id}`).then( (response) => {
			const { success, errors = [], data, message } = response?.data;
			if(success){
			  setFields({status: data.status});
			  setFaq(data);
			}
		});
	}, []);
	
	const handleChange = (e) => {
		setFields({status: e.target.value});
	}

  const editFaqHandler = (e) => {
  	e.preventDefault();
    enableLoading();
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "faq":{
          "id": faqInfo.id,
          "uuid": faqInfo.uuid,
          "title": title.current.value,
          "description": description.current.value,
          "order": order.current.value,
          "status": status.current.value,
          "createdBy": faqInfo.createdBy,
          "updatedBy": faqInfo.updatedBy,
          "deleted": faqInfo.deleted
        }
      })
    };
    fetch(`${API_URL}${faq}/${userId}/edit`, requestOptions)
    .then(response => {
      disableLoading();
			if(response.status === 200){	
        alertify.success("Record updated successfully");
        props.history.push("/faq/list");	  
      }
    })
    .catch(() => {
      disableLoading();
      alertify.error("Internal server error occurred trying to update the record.");
      return null;
    });
  }
  return (
    loading ?
    <div style={{opacity: 0.9, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
      <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} >
        <Spinner animation="grow" variant='warning' />
      </div>
    </div>:
    <Row>
      <Col md={12}>
      <Card>
        <CardHeader title="Edit Faq">
          <CardHeaderToolbar>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          
          <div className="mt-5">
            <Form onSubmit={editFaqHandler}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Title</Form.Label>
                <Form.Control ref={title} defaultValue={faqInfo.title} placeholder="Enter title" />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Order</Form.Label>
                <Form.Control ref={order} defaultValue={faqInfo.order} placeholder="Enter order" />
              </Form.Group>
              
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" ref={status} onChange={handleChange} value={fields.status}>
                  <option value="true">Active</option>
                  <option value="false">In-Active</option>
                  
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" ref={description} defaultValue={faqInfo.description} placeholder="Enter description" />
              </Form.Group>
            
            </Form.Row>
          <Button variant="primary" className="float-right" type="submit">
            Save
          </Button>
        </Form>
              
          </div>

        </CardBody>
      </Card>
      </Col>
    </Row>
  );
}