import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";

import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getCityLists, getTerminalDetails, updateTerminalDetails } from "../../../app/sagas/routeManagement";

export function EditTerminal(props) {

  const { userId } = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(true);
  const [terminalObject, setTerminal] = useState({});
  const [cities, setCities] = useState([]);
  const [image, setImage] = useState('');
  const id = props.match.params.id;

	const initialState = {
    city_id: ''
	};

	
	const [fields, setFields] = useState(initialState);
  const name = useRef();
  const description = useRef();
  const latitude = useRef();
  const longitude = useRef();
  const city_id = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await getTerminalDetails( id );
        const { success, data = {}, errors = [] } = apiResponse?.data;
        if(success){
          setTerminal(data);
          setFields({ city_id: data.City.uuid });
          const img = data.image !== null ? new Buffer.from(data.image).toString("ascii") : null;
          setImage(img);
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      }
		};
		fetchData();
	}, []);

  useEffect(() => {
		const fetchData = async () => {
			try {
        const apiResponse = await getCityLists(0, 200);
        const { data = [], success } = apiResponse?.data;
        if (success) {
          setCities(data);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }	
		};
		fetchData();
	}, []);
  
  const handleImage = (e) =>{
    var reader = new FileReader(); 
    reader.onload = (e) => {
      setTerminal((currentState)=>({
        ...currentState,
        image: e.target.result
      }));
      setImage(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }
	
  const handleCityChange = (e) => {
    setFields({city_id: e.target.value});
  }

  const editTerminalHandler = async(e) => {
  	e.preventDefault();
    setLoading(true);
    try {
      const apiResponse = await updateTerminalDetails({
        "terminal":{
          "id": terminalObject.id,
          "uuid": terminalObject.uuid,
          "name": name.current.value,
          "description": description.current.value,
          "cityId": city_id.current.value,
          "image": image,
          "latitude": latitude.current.value,
          "longitude": longitude.current.value,
          "status": terminalObject.status,
          "createdBy": terminalObject.createdBy,
          "updatedBy": terminalObject.updatedBy,
          "deleted": terminalObject.deleted,
          "version": terminalObject.version,
          "updatedAt": terminalObject.updatedAt,
          "createdAt": terminalObject.createdAt
        }
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record updated successfully");
        props.history.push("/terminal/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    loading ? <LoaderScreen /> :
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Edit Terminal"/>
            <CardBody>        
              <div className="mt-5">
                <Form onSubmit={editTerminalHandler}>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control ref={name} defaultValue={terminalObject.name} placeholder="Enter name" />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>City</Form.Label>
                      <Form.Control as="select" ref={city_id} onChange={handleCityChange} value={fields.city_id}>
                        <option key='' value=''>Select City</option>
                        {cities.map((city) => (
                          <option key={city.uuid} value={city.uuid}>{city.name}</option>
                        ))}                        
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Latitude</Form.Label>
                      <Form.Control ref={latitude} defaultValue={terminalObject.latitude} placeholder="Enter latitude" />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Longitude</Form.Label>
                      <Form.Control ref={longitude} defaultValue={terminalObject.longitude} placeholder="Enter longitude" />
                    </Form.Group>              
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" ref={description} defaultValue={terminalObject.description} placeholder="Enter description" />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Image</Form.Label>
                      <Row>
                        <Col><Form.Control type="file" onChange={handleImage} /></Col>
                        <Col>{terminalObject.image ? <img width='100' src={`${image}`} /> : ''}</Col>
                      </Row>
                    </Form.Group>
                  </Form.Row>
                  <Button variant="primary" className="float-right" type="submit"> Save </Button>
                </Form>             
              </div>
            </CardBody>
        </Card>
      </Col>
    </Row>
  );
}