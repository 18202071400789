import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import $ from "jquery";
import Button from 'react-bootstrap/Button';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {API_URL, transaction, } from '../constants.js';
import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import { handleResponse } from '../../../_helpers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';
import TabPane from 'react-bootstrap/TabPane';
import TabContent from 'react-bootstrap/TabContent';
import TabContainer from 'react-bootstrap/TabContainer';
import { LoaderScreen } from '../../modules/Loader';

export function BookingDetail(props) {
  const id = props.match.params.id;
	const [fields, setFields] = useState({
		paymentStatus: '',
    usageStatus: ''
	});
  const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
  const [paymentUpdates, setPaymentUpdates] = useState(false);
  const [booking, setBooking] = useState({});
  const [bookingStatus, setBookingStatus] = useState({
    status: '',
    variant: ''
  });
  const [bookingPayment, setBookingPayment] = useState({
    status: '',
    variant: ''
  });
  const [ticketOwner, setTicketOwner] = useState('');
  const [busInformation, setBusInformation] = useState({});
  const [departure, setDeparture] = useState('');
  const [destination, setDestination] = useState('');
  const [departureTime, setDepartureTime] = useState('');
  const [arrivalTime, setArrivalTime] = useState('');
  const [passengers, setPassengers] = useState([]);
  const [passenger, setPassenger] = useState([]);
  const paymentStatus = useRef();
  const usageStatus = useRef();

  const handlePassengerUpdates = (passenger, index)=>{
    setOpen(true);
    setPassenger(passenger);
    setFields({
      usageStatus: passenger.status,
      paymentStatus: booking.isPaid
    });
  }

  const handlePaymentUpdates = ()=>{
    setPaymentUpdates(true);
    setFields({
      usageStatus: booking.status,
      paymentStatus: booking.isPaid
    });
  }

  const handleClose = ()=>{
    setOpen(false);
    setPaymentUpdates(false);
  }

  const handleSubmit = ()=>{
    let formatTwoDigits = (digit) => ("0" + digit).slice(-2);
    var tempDate = new Date();
    var date = `${tempDate.getFullYear()}-${formatTwoDigits(tempDate.getMonth()+1)}-${formatTwoDigits(tempDate.getDate())} ${formatTwoDigits(tempDate.getHours())}:${formatTwoDigits(tempDate.getMinutes())}:${formatTwoDigits(tempDate.getSeconds())}`;
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "bookings":{
          "id": booking.id,
          "uuid": booking.uuid,
          "reservationNumber": booking.reservationNumber,
          "bookingDate": booking.bookingDate,
          "ticketPrice": booking.ticketPrice,
          "fare": booking.fare,
          "seats": booking.seats,
          "passengers": booking.passengers,
          "departureSequenceNumber": booking.departureSequenceNumber,
          "arrivalSequenceNumber": booking.arrivalSequenceNumber,
          "isPaid": paymentStatus.current.value,
          "cancelled_at": booking.cancelledAt,
          "status": booking.booking,
          "user": booking.user,
          "busSubRouteId": booking.busSubRouteId,
          "busId": booking.busId
        }
      })
    };

    fetch(`${API_URL}${transaction}/bookings/${booking.uuid}/edit`, requestOptions).then(handleResponse).then(response => {      
      alertify.success("Bookings updated successfully");
      queryBookingDetails(id);
      setPaymentUpdates(false);
    }).catch(() => {
      setPaymentUpdates(false);
      alertify.error("Internal server error occurred trying to update booking record.");
      return null;
    });    
  }
  const handleUsageChange = (e) => {
    setFields({usageStatus: e.target.value});
  }
  const handlePaymentChange = (e) => {
    setFields({paymentStatus: e.target.value});
  }

  const queryBookingDetails = (bookingId) =>{
    axios.get(`${API_URL}${transaction}/bookings/${bookingId}`).then( (response) => {
			const { success, data } = response?.data;
			if(success){
        $("#passenger_table").DataTable();
				setBooking(data);
        setTicketOwner(data?.User?.fname + " " + data?.User?.lname);
        setBusInformation(data?.Bus);
        setDeparture(data?.departure.name);
        setDestination(data?.arrival.name);

        setDepartureTime(data?.departureTime);
          setArrivalTime(data?.arrivalTime);
        setPassengers(data?.passengers); 
        if(data.status === "active"){
          setBookingStatus({
            status: "Active",
            variant: "primary"
          });
        } else if(data.status === "cancelled"){
          setBookingStatus({
            status: "Cancelled",
            variant: "danger"
          });
        } else if(data.status === "used"){
          setBookingStatus({
            status: "Used",
            variant: "secondary"
          });
        }
        if(data.isPaid === "No"){
          setBookingPayment({
            status: "Pending",
            variant: "warning"
          });
        } else if(data.isPaid === "Yes"){
          setBookingPayment({
            status: "Paid",
            variant: "primary"
          });
        }
			}
		});
  }

  const handlePassengerSubmit = ()=>{
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "passenger":{          
          "id": passenger.id,
          "uuid": passenger.uuid,
          "names": passenger.names,
          "seatNumber": passenger.seatNumber,
          "status": usageStatus.current.value,
          "createdAt": passenger.createdAt,
          "updatedAt": passenger.updatedAt,
          "deletedAt": passenger.deletedAt,
          "bookings": passenger.bookings,
          "version": passenger.version
        }
      })
    };

    fetch(`${API_URL}${transaction}/bookings/${booking.uuid}/passengers/${passenger.uuid}/edit`, requestOptions).then(handleResponse).then(response => {      
      alertify.success("Passenger record updated successfully");
      queryBookingDetails(id);
      handleClose();
    }).catch(() => {
      handleClose();
      alertify.error("Internal server error occurred trying to update the record.");
      return null;
    });    
  }

  useEffect( () => {
    try{
      queryBookingDetails(id);
    }finally{
      setLoading(false);
    }
  }, [id]);
	
  return ( 
    loading ? <LoaderScreen /> :
    <>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader title="Booking Detail"/>
            <CardBody>
              <TabContainer id="left-tabs-example" defaultActiveKey="bookings_details">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item> <Nav.Link eventKey="bookings_details">Booking Information</Nav.Link></Nav.Item>
                      <Nav.Item> <Nav.Link eventKey="travel_information">Travel Details</Nav.Link> </Nav.Item>
                      <Nav.Item> <Nav.Link eventKey="passenger_information">Passengers</Nav.Link> </Nav.Item>                    
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <TabContent>
                      <TabPane eventKey="bookings_details">
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Reservation No.</Form.Label>
                            <Form.Control defaultValue={booking.reservationNumber} readOnly disabled />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Booking Date</Form.Label>
                            <Form.Control defaultValue={booking.bookingDate} readOnly disabled />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Booking Status</Form.Label> <br />
                            <Badge variant={bookingStatus.variant}>{bookingStatus.status}</Badge>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Payment Status</Form.Label>
                            <br />
                            <Badge variant={bookingPayment.variant}>{bookingPayment.status}</Badge>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Ticket Price</Form.Label>
                            <Form.Control defaultValue={booking.fare} readOnly disabled />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Total Price</Form.Label>
                            <Form.Control defaultValue={booking.ticketPrice} readOnly disabled />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Booked By</Form.Label>
                            <Form.Control defaultValue={ticketOwner} readOnly disabled />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Booked Seats</Form.Label>
                            <Form.Control defaultValue={ booking.seats} readOnly disabled />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Button variant="primary" className="float-right" onClick={() => handlePaymentUpdates()}>Update Payment Status</Button>
                          </Form.Group>
                        </Form.Row>
                      </TabPane>
                      <TabPane eventKey="travel_information">
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Bus Operator</Form.Label>
                            <Form.Control defaultValue={busInformation.owner} readOnly disabled />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Bus Booked</Form.Label>
                            <Form.Control defaultValue={busInformation.regNo} readOnly disabled />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Departure Place</Form.Label>
                            <Form.Control defaultValue={departure} readOnly disabled />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Arrival Place</Form.Label>
                            <Form.Control defaultValue={destination} readOnly disabled />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Departure Time</Form.Label>
                            <Form.Control defaultValue={departureTime} readOnly disabled />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Arrival Time</Form.Label>
                            <Form.Control defaultValue={arrivalTime} readOnly disabled />
                          </Form.Group>
                        </Form.Row>
                      </TabPane>
                      <TabPane eventKey="passenger_information">
                        <table id="passenger_table" className="sharearide_datatable">
                          <thead>
                            <tr>
                              <th>Ref</th>
                              <th>Names</th>
                              <th>Seat</th>
                              <th>Reservation Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {passengers.length > 0 ? (
                              passengers.map((passenger, index) => (
                                <tr key={passenger.uuid}>
                                  <td>{passenger.id}</td>
                                  <td>{passenger.names}</td>
                                  <td>{passenger.seatNumber}</td>
                                  <td>{
                                    (passenger.status === "active") ?
                                      <Badge variant="primary">Active</Badge>
                                    : ((passenger.status === "cancelled") ?
                                      <Badge variant="danger">Cancelled</Badge>
                                    : (passenger.status === "used")?
                                      <Badge variant="secondary">Used</Badge> : "")
                                  }</td>
                                  <td>
                                    <a onClick={() => handlePassengerUpdates(passenger, index)} key={passenger.id} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                                      <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
                                      </span>
                                    </a>				        				
                                  </td>					            
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td ></td>
                                <td ></td>
                                <td >No Passengers found</td>
                                <td ></td>
                                <td ></td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </TabContainer>          
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Dialog open={paymentUpdates} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Payment Status</DialogTitle>
        <DialogContent>
          <DialogContentText>By changing the status of this bookings you acknowledge to take full responsibility of all audits?</DialogContentText>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Payment Status</Form.Label>
              <Form.Control as="select" ref={paymentStatus} margin="dense" onChange={handlePaymentChange} value={fields.paymentStatus}>
                <option value="Yes">Paid</option>
                <option value="No">Pending</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="danger">
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Payment Status</DialogTitle>
          <DialogContent>
            <DialogContentText>By changing the status of this Passenger Reservation you acknowledge to take full responsibility of all audits?</DialogContentText>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Usage Status</Form.Label>
                <Form.Control as="select" ref={usageStatus} margin="dense" onChange={handleUsageChange} value={fields.usageStatus}>
                  <option value="active">Active</option>
                  <option value="used">Used</option>
                  <option value="cancelled">Cancelled</option>
                </Form.Control>
              </Form.Group>              
			      </Form.Row>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="primary">
              Cancel
            </Button>
            <Button onClick={handlePassengerSubmit} variant="danger">
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}