import React, { useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { makeStyles } from '@material-ui/core/styles';
import {API_URL, faq} from '../constants.js';
import { handleResponse } from '../../../_helpers';
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

export function CreateFaq(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { userId, role } = useSelector((state) => state.Auth.user);

  const title = useRef();
  const description = useRef();
  const order = useRef();
  const status = useRef();
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const createFaqHandler = (e) => {
  	e.preventDefault();
    enableLoading();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "faq":{
          "id": null,
          "uuid": null,
          "title": title.current.value,
          "description": description.current.value,
          "order": order.current.value,
          "status": status.current.value,
          "createdBy": null,
          "updatedBy": null,
          "deleted": false
        }
      })
    };
    fetch(`${API_URL}${faq}/create/${userId}`, requestOptions)
    .then(response => {
      disableLoading();
      if(response.status === 200){	
        alertify.success("Route added successfully");
        props.history.push("/faq/list");
      } else{
        alertify.error("Error occurred trying to create record");
        return null;
      }
    })
    .catch((err) => {
      disableLoading();
      alertify.error("Internal server error occurred trying to create the record.");
        return null;
    });
  }
  return (
  <Row>
  	<Col md={12}>
  	<Card>
      <CardHeader title="Add New Faq">
        <CardHeaderToolbar>
          
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        
        <div className="mt-5">
        	<Form onSubmit={createFaqHandler}>
        	<Form.Row>
    			  <Form.Group as={Col}>
    			    <Form.Label>Title</Form.Label>
    			    <Form.Control ref={title} placeholder="Enter title" />
    			  </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Order</Form.Label>
              <Form.Control ref={order} placeholder="Enter order" />
            </Form.Group>    			   
    			</Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" ref={status}>
                <option value="true">Active</option>
                <option value="false">In-Active</option>
                
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" ref={description} placeholder="Enter description" />
            </Form.Group>
          </Form.Row>
			  <Button variant="primary" className="float-right" type="submit">
			    Save
			  </Button>
			</Form>
             
        </div>

      </CardBody>
    </Card>
    </Col>
   </Row>
  );
}