import axios from "../../config/axiosConfig";
import { formatRequest } from "./common";

const { REACT_APP_API_VERSION } = process.env;

export function fetchDashboardData(role) {
  return axios(
    formatRequest(
      `/api/v2/app/global/${role}`,
      "get",
      "",
      true,
      true
    )
  );
}