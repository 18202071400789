import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";

import { logout } from "../_redux/authCrud";

class Logout extends Component {
  componentDidMount() {
    logout();
    window.localStorage.clear();
    window.location.reload();
  }

  render() {
    return <Redirect to="/auth/login" />;
  }
}

export default Logout;
