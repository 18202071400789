import { PURGE } from "redux-persist";
import { events } from "../actions";

const INITIAL_STATE = { loading: false, apiType: null, actionName: null };

export default (
  state = { loading: false, apiType: null, actionName: null },
  response
) => {
  switch (response.type) {
    case events.loading.RECEIVED:
      return {
        loading: true,
        apiType: response.apiType,
        actionName: response.actionName,
      };
    case events.loading.COMPLETED:
      return {
        loading: false,
        apiType: null,
        actionName: null,
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
