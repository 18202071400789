import React, { useEffect, useState, } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Line, Pie, Bar, Doughnut} from 'react-chartjs-2';
import {DashboardWidget} from "../widgets/mixed/DashboardWidget.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { fetchDashboardData } from "../../../app/sagas/global";

export function Dashboard() {

  const [monthWiseTickets, setMonthWiseTickets] = useState({});
	const colors = [getRandomColor(),getRandomColor(),getRandomColor()];

  const [terminalWiseTickets, setTerminalWiseTickets] = useState({});
  const [cityWiseTickets, setCityWiseTickets] = useState({});
  const [dayWiseTickets, setDayWiseTickets] = useState({});

  const [dashboard, setDashboard] = useState({
    total_users: 0,
    total_buses: 0,
    total_terminals: 0,
    total_agents: 0,
  });

  useEffect( () => {
    const fetchData = async () => {
      const response = await fetchDashboardData("super");
      const { success, errors = [], data, message } = response?.data;
      if(success){
        setDashboard({
          total_agents: data.totalAgents,
          total_buses: data.totalBuses,
          total_terminals: data.totalTerminals,
          total_users: data.totalUsers
        });
        if(data.terminalsTicketStatistics && data.terminalsTicketStatistics !== undefined && data.terminalsTicketStatistics !== null){
          if(Array.isArray(data.terminalsTicketStatistics)){
            if(data.terminalsTicketStatistics.length > 0){
              setTerminalWiseTickets({
                label: 'Terminal Wise Tickets',
                labels: data.terminalsTicketStatistics.map(info => info.terminalName),
                datasets: [{
                    backgroundColor: [
                      getRandomColor(),
                      getRandomColor(),
                      getRandomColor(),
                      getRandomColor(),
                      getRandomColor()
                    ],
                    "borderWidth": [
                      1,1,1,1,1
                    ],
                    data: data.terminalsTicketStatistics.map(info => info.numberOfTickets)
                  }],
              });
            }
          }
        }
        
        if(data.citiesTicketStatistics && data.citiesTicketStatistics !== undefined && data.citiesTicketStatistics !== null){
          if(Array.isArray(data.citiesTicketStatistics)){
            if(data.citiesTicketStatistics.length > 0){
              setCityWiseTickets({
                label: 'City Wise Tickets',
                labels: data.citiesTicketStatistics.map(info => info.cityName),
                datasets: [{
                    backgroundColor: [
                      getRandomColor(),
                      getRandomColor(),
                      getRandomColor(),
                      getRandomColor(),
                      getRandomColor()
                    ],
                    "borderWidth": [
                      1,1,1,1,1
                    ],
                    data: data.citiesTicketStatistics.map(info => info.numberOfTickets)
                  }],
              });
            }
          }
        }
        
        if(data.lastSevenDaysBookingResult && data.lastSevenDaysBookingResult !== undefined && data.lastSevenDaysBookingResult !== null){
          if(Array.isArray(data.lastSevenDaysBookingResult)){
            if(data.lastSevenDaysBookingResult.length > 0){
              setDayWiseTickets({
                labels: data.lastSevenDaysBookingResult.map(info => info.Day),
                datasets: [{
                    barPercentage: 1,
                    barThickness: 50,
                    maxBarThickness:50,
                    minBarLength: 0,
                    data: data.lastSevenDaysBookingResult.map(info => info.total),
                    backgroundColor: [
                    getRandomColor(),
                    getRandomColor(),
                    getRandomColor(),
                    getRandomColor(),
                    getRandomColor(),
                    getRandomColor(),
                    getRandomColor()              
                  ],
                }]
              });
            }
          }
        }        
        
        // Month Wise Tickets Line Chart
        if(data.annualStatistics.allTickets && data.annualStatistics.allTickets !== undefined && data.annualStatistics.allTickets !== null){
          if(Array.isArray(data.annualStatistics.allTickets)){
            if(data.annualStatistics.allTickets.length > 0){
              setMonthWiseTickets({
                labels: data.annualStatistics.allTickets.map(info => info.Month),
                datasets: [
                  {
                    label: 'All',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: colors[0],
                    borderColor: colors[0],
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: colors[0],
                    pointBackgroundColor: colors[0],
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: colors[0],
                    pointHoverBorderColor: colors[0],
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.annualStatistics.allTickets.map(info => info.total),
                  },
                  {
                    label: 'Confirmed',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: colors[1],
                    borderColor: colors[1],
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: colors[1],
                    pointBackgroundColor: colors[1],
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: colors[1],
                    pointHoverBorderColor: colors[1],
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.annualStatistics.usedTickets.map(info => info.total),
                  },              
                  {
                    label: 'Cancelled',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: colors[2],
                    borderColor: colors[2],
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: colors[2],
                    pointBackgroundColor: colors[2],
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: colors[2],
                    pointHoverBorderColor: colors[2],
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.annualStatistics.cancelledTickets.map(info => info.total)
                  },              
                  {
                    label: 'Active',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: getRandomColor(),
                    borderColor: getRandomColor(),
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: getRandomColor(),
                    pointBackgroundColor: getRandomColor(),
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: getRandomColor(),
                    pointHoverBorderColor: getRandomColor(),
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.annualStatistics.activeTickets.map(info => info.total)
                  }
                ]  
              });
            }
          }
        }
      }
    };
    fetchData();
  }, []);

  const options={
    responsive: true,
    legend: {
      display: false,
    },           
    scales: {
      yAxes: [{
      stacked: true
    }]
    }
  };
	return(		
		<>
		  <Row>
			  <Col md={3}>
				  <DashboardWidget  
					  className="gutter-b"
	          symbolShape="circle"
	          baseColor="danger"
	          mainTitle="Total Users"
	          value={dashboard.total_users}
	          icon="General/User.svg"
				  />
			  </Col>
        <Col md={3}>
          <DashboardWidget  
            className="gutter-b"
            symbolShape="circle"
            baseColor="primary"
            mainTitle="Total Buses"
            value={dashboard.total_buses}
            icon="Custom/Layout-3d.svg"
          />
        </Col>
        <Col md={3}>
          <DashboardWidget  
            className="gutter-b"
            symbolShape="circle"
            baseColor="warning"
            mainTitle="Total Terminals"
            value={dashboard.total_terminals}
            icon="Layout/Layout-4-blocks.svg"
          />
        </Col>
        <Col md={3}>
          <DashboardWidget  
            className="gutter-b"
            symbolShape="circle"
            baseColor="success"
            mainTitle="Total Agents"
            value={dashboard.total_agents}
            icon="General/User.svg"
          />
        </Col>
		  </Row>
      <Row>
      	<Col md={6}>
				  <Card>
	      		<CardHeader title="Tickets By City"/>
				    <CardBody>
		          <Doughnut data={cityWiseTickets} />
		        </CardBody>
		      </Card>
	      </Col>      		
	      <Col md={6}>
          <Card>
            <CardHeader title="Tickets By Terminals"/>
            <CardBody>
              <Pie data={terminalWiseTickets} />
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardHeader title="Tickets Per Day (Last 7 Days)"/>
            <CardBody>
              <Bar data={dayWiseTickets} options={options} />
            </CardBody>
          </Card>
	      </Col>
	      <Col md={6}>
				  <Card>
	      		<CardHeader title="Tickets Per Month (Last 12 Months)"/>
				    <CardBody>
		          <Line data={monthWiseTickets} />
		        </CardBody>
		      </Card>
	      </Col>
	    </Row>
    </>
	)
}

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 10)];
  }
  return color;
}