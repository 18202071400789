import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { history } from "../../../../_helpers/history";
import { AuthRouting } from "../../../../_helpers/routingManagement";
import { actions } from "../../../actions";
import { persister } from "../../../../redux/store";
import { LOCAL_STORAGE_CUSTOMER_DATA_KEY } from "../../../pages/constants";
import * as errorCodes from "../../../utils/errorCodes";
import Timer from "../../../utils/Timer";

function Login({  
  login,
  loading,
  errorCode,
  errorMessage,
  isAuthenticated,
  closeError,
  clearLoading,
  lockStartTime,
  authenticatedUser,
}) {
    
  const [savedCustomerData, setSavedCustomerData] = useState({});
  let formickActions = null;
  const intl = useIntl();
  const loginSchema = yup.object({
    email: yup
      .string()
      .email("Wrong email format")
      .min(3, "Minimum 5 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  useEffect(() => {
    if (isAuthenticated) {
      if((window !== undefined) && (window.location !== undefined) && (window.location.state !== undefined) && (window.location.state.requestedPath !== undefined)){
        history.push({
          pathname: window.location.state?.requestedPath,
          search: window.location.search,
        });
      } else{
        if(authenticatedUser.role === "super"){
          history.push({
            pathname: AuthRouting.superAdminDashboard,
            search: window.location.search,
          });
        } else if(authenticatedUser.role === "admin"){
          history.push({
            pathname: AuthRouting.adminDashboard,
            search: window.location.search,
          }); 
        } else if(authenticatedUser.role === "agent"){
          history.push({
            pathname: AuthRouting.agentDashboard,
            search: window.location.search,
          }); 
        }
      }
      window.location.reload();
    } else {
      persister.purge();
    }
  }, [isAuthenticated]);
  
  useEffect(() => {
    clearLoading();
  }, [clearLoading]);

  const resetErrors = () => {
    window.localStorage.setItem(
      LOCAL_STORAGE_CUSTOMER_DATA_KEY,
      JSON.stringify({
        ...setSavedCustomerData,
        lockStartTime: null,
        errorCode: null,
      })
    );
    setSavedCustomerData({});
    closeError();
  };

  useEffect(() => {
    try {
      const cacheData = JSON.parse( window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY) || "{}" ) || {};
      setSavedCustomerData(cacheData);
      if (cacheData && cacheData.email) {
        formickActions.setFieldValue("email", cacheData.email);
      }
    } catch (e) {}
  }, []);

  const getInputClasses = (fieldname) => {
    if (formickActions.touched[fieldname] && formickActions.errors[fieldname]) {
      return "is-invalid";
    }

    if (formickActions.touched[fieldname] && !formickActions.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  }; 

  const LoginHandler = (values) => {
    login(values.email, values.password, values.rememberMe);
  };

  const parseErrorCode = () => {
    if (!errorCode) {
      return null;
    }
    if (errorCode === errorCodes.LOGIN_FAILED) {
      const translationKey = savedCustomerData?.email !== "" ? "LOGIN_WELCOME_BACK_ERROR" : errorCode;
      return intl.formatMessage({
        id: translationKey,
      });
    }

    if (errorCode === errorCodes.ATTEMPTS_EXCEEDED) {
      return (
        <FormattedMessage
          id="ATTEMPTS_EXCEEDED"
          values={{
            time: (
              <Timer
                timeInMinutes={60}
                initialStartTime={lockStartTime}
                onFinish={resetErrors}
              />
            ),
          }}
        />
      );
    }
    
    if (errorCode !== "GENERIC_ERROR" && intl.formatMessage({id: errorCode,})) {
      return intl.formatMessage({
        id: errorCode,
      });
    }

    if (errorCode === errorCodes.GENERIC_ERROR) {
      if(errorMessage){
        return errorMessage;
      }
      return intl.formatMessage({
        id: "GENERIC_ERROR",
      });
    }
    return errorMessage;
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      <Formik
        initialValues={{
          email: savedCustomerData?.email || "",
          password: "",
          rememberMe: true,
        }}
        onSubmit={(values) => LoginHandler(values)}
        validationSchema={loginSchema}
      >
        {(formikProps) => {
          formickActions = formikProps;
          return (
            <Form className="form fv-plugins-bootstrap fv-plugins-framework" >
              {errorCode && (!savedCustomerData || !savedCustomerData.email) && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{parseErrorCode()}</div>
                </div>
              )}

              <div className="form-group fv-plugins-icon-container">
                <input placeholder="Email" type="email" className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`} name="email" {...formickActions.getFieldProps("email")} />
                {formickActions.touched.email && formickActions.errors.email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formickActions.errors.email}</div>
                  </div>
                )}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Password"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formickActions.getFieldProps("password")}
                />
                {formickActions.touched.password && formickActions.errors.password &&(
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formickActions.errors.password}</div>
                  </div>
                )}
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                {!formickActions.isSubmitting ?
                  (<Link to="/auth/forgot-password" className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot" > Forgot Password ?</Link>):
                  (<Link to="#" className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot" > Forgot Password ?</Link>)
                }
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={
                    loading || !(formikProps.values.email && formikProps.values.password) || (errorCode === errorCodes.ATTEMPTS_EXCEEDED)
                  }
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                >
                  <span>Sign In</span>
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
              </div>
            </Form> 
          );
        }}
      </Formik>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authenticatedUser: state.Auth.user,
    errorCode: state.Auth.errorCode,
    errorMessage: state.Auth.errorMessage,
    loading: state.Loading.loading,
    isAuthenticated: state.Auth.isAuthenticated,
    lockStartTime: state.Auth.lockStartTime,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: ( user, password, saveUserDataInCache ) => {
      dispatch(actions.auth.requestLogin( user, password, saveUserDataInCache ));
      dispatch(actions.loading.requestLoadingAction());
    },
    closeError: () => {
      dispatch(actions.error.cancelErrorAction());
    },
    clearLoading: () => {
      dispatch(actions.loading.completeLoadingAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
