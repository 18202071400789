import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import Select from 'react-select';
import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import { LoaderScreen } from '../../modules/Loader';
import { getBus } from "../../../app/sagas/busManagement";

export function ViewBus(props) {

  const [loading, setLoading] = useState(true);
	const [bus, setBus] = useState({});
	const [fields, setFields] = useState({
		bus: '',
    status: '',
    layout: '',
    lastseat: '',
    bus_type_id: '',
    partner_id: '',
	});
  const id = props.match.params.id;
  const [image, setImage] = useState('');
  const [allowedFacilities, setAllowedFacilities] = useState([]);
  
  useEffect(() => {    
    const fetchData = async () => {
      try {
        const apiResponse = await getBus( id );
        const { success, data = {}, errors = [] } = apiResponse?.data;
        if(success){
          setFields({
            status: data.Bus.status,
            layout: data.Bus.layout,
            lastseat: data.Bus.lastseat,
            bus_type_id: data?.BusType?.name,
            partner_id: data?.BusOperator?.name
          });
          setBus(data);
          const img = data.Bus.image != null ? new Buffer.from(data.Bus.image).toString("ascii") : null;
          setImage(img);
          if(data.BusFacilities != undefined && data.BusFacilities != null){
            for(var i = 0; i < data.BusFacilities.length; i++){
              allowedFacilities.push({
                value: data.BusFacilities[i].BusFacility.facility,
                label: data.BusFacilities[i].Facilities.name
              });
            }
            setAllowedFacilities([...allowedFacilities]);
          }
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }
		};
		fetchData();
	}, []);
	
  return (
    loading ? <LoaderScreen /> :
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Edit Bus"/>
          <CardBody>
            <div className="mt-5">
              <Form >
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Bus Type</Form.Label>
                    <Form.Control value={fields.bus_type_id} disabled/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control value={fields.status ? "Active" : "In-Active"} disabled/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Reg No</Form.Label>
                    <Form.Control value={bus?.Bus?.regNo} disabled/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Engine No</Form.Label>
                    <Form.Control value={bus?.Bus?.engineNo} disabled/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Model No</Form.Label>
                    <Form.Control value={bus?.Bus?.modelNo} disabled/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Chasis No</Form.Label>
                    <Form.Control value={bus?.Bus?.chasisNo} disabled/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Owner</Form.Label>
                    <Form.Control value={bus?.Bus?.owner} disabled/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Company</Form.Label>
                    <Form.Control value={bus?.Bus?.company} disabled/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Layout</Form.Label>
                    <Form.Control value={fields.layout} disabled/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>No Of Rows</Form.Label>
                    <Form.Control value={bus?.Bus?.noOfRows} disabled/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Last Seat</Form.Label>
                    <Form.Control value={fields.lastseat ? "Yes" : "No"} disabled />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Total Seats</Form.Label>
                    <Form.Control value={bus?.Bus?.totalSeats} disabled />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Partner</Form.Label>
                    <Form.Control value={fields.partner_id} disabled />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Image</Form.Label>
                    <Row>
                      <Col>{image? <img width='100' src={image} alt=""/> : ''}</Col>
                    </Row>
                  </Form.Group>
                </Form.Row>              
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Available Facilities</Form.Label>
                    <Select isMulti value={allowedFacilities}/>
                  </Form.Group>
                  <Form.Group as={Col}></Form.Group>
                </Form.Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}