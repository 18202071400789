export const EVENT_EXPLORE = "event_explore";
export const EVENT_DO_N0T_HAVE_LOAN = "event_do_not_have_loan";

export const COUNTRY_KEY = "country";

export const Login = {
  START: "login_start_cta",
  SUCCESS: "login_success",
  FAILED: "login_failed",
  INTERIM_DASHBOARD: "interim_dashbaord",
};

export const ForgotPassProfile = {
  START: "fp_flow_start",
  REQ_OTP_MAIL_SUCCESS: "fp_req_otp_mail_success",
  REQ_OTP_PHONE_SUCCESS: "fp_req_otp_mob_success",
  REQ_OTP_FAILURE: "fp_req_otp_failure",
  VERIFY_PASS_SUCCESS: "fp_verify_pass_success",
  VERIFY_PASS_FAILURE: "fp_verify_pass_failure",
  SETUP_NEW_PASS: "fp_setup_pass_start",
  SUCCESS: "fp_setup_pass_success",
  FAILURE: "fp_setup_pass_failure",
};

