import React, {useEffect, useState} from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import { useSelector } from "react-redux";
import { getBusRoutes, removeBusRoute } from "../../../app/sagas/busManagement";
import { LoaderScreen } from '../../modules/Loader';

export function BusRouteList(){

	const { userId } = useSelector((state) => state.Auth.user);
	const [deleting, setDeleting] = useState(false);
	const [loading, setLoading] = useState(true);
	const [busRoutes, setBusRoutes] = useState([]);
	const [busRoute, setBusRoute] = useState("");
	const [show, setShow] = useState(false);
	const history = useHistory();
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(2000);
	
	const setCurrentBusRoute = (busRoute) => {
	    setBusRoute(busRoute);
	    setShow(true);

	};

  	const handleClose = () => setShow(false);

  	const deleteBusRoute = async() => {
		try {
			setDeleting(true)
			const apiResponse = await removeBusRoute(busRoute, userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
			  alertify.success("Record removed successfully");
			  history.go(0);
			} else{
			  alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setShow(false);
			setDeleting(false);
		}
  	}

	useEffect( () => {
		const fetchData = async () => {
			try {
				const apiResponse = await getBusRoutes(offset, limit);
				const { data = [], success } = apiResponse?.data;
				if (success) {
					setBusRoutes(data);
				}
			} catch (error) {
				alertify.error(error.message);
			}
		};
		fetchData();
	}, [offset, limit]);

	useEffect(() => {
		if(busRoutes.length > 0){
			$("#bus_route_table").DataTable({responsive: true, stateSave: true});
		}
		setLoading(false);
	}, [busRoutes]);

	return (
		loading ? <LoaderScreen /> :
		<>
			<table id="bus_route_table" className="sharearide_datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>Bus</th>
						<th>Main Route</th>
						<th>Distance (KM)</th>
						<th>Duration (Mins)</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{busRoutes.length > 0 ? (
						busRoutes.map((busRoute, index) => (
						<tr key={busRoute?.Bus?.id}>
							<td>{busRoute?.Bus?.id}</td>
							<td>{busRoute?.Bus?.regNo}</td>
							<td>{busRoute?.Route?.name}</td>
							<td>{busRoute?.Route?.distance}</td>
							<td>{busRoute?.Route?.approximateTime}</td>					
							<td>
								<Link to={`/bus-route/edit/${busRoute.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
									<span className="svg-icon svg-icon-md svg-icon-primary">
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
									/>
									</span>
								</Link>
								<a 
								onClick={() => setCurrentBusRoute(busRoute.uuid)}
								key={index}
								className="btn btn-icon btn-light btn-hover-danger btn-sm">
									<span className="svg-icon svg-icon-md svg-icon-danger">
									<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
									</span>
								</a>
							</td>
							</tr>
						))
					) : (
						<tr>
						<td colSpan={6}>No Bus Route</td>
						</tr>
					)}
				</tbody>
			</table>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Bus Route</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to Delete?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose} disabled={ deleting }>
						Cancel
					</Button>
					<Button variant="primary" onClick={deleteBusRoute} disabled={ deleting }>
					<span>Yes</span> {deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
					</Button>
				</Modal.Footer>
			</Modal>
	    </>
	)
}