import React, {useEffect, useState,} from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl, } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getAgents, getUser, removeAgent } from "../../../app/sagas/userManagement";

export function AgentList(props){

	const [loading, setLoading] = useState(true);
  	const { userId, role } = useSelector((state) => state.Auth.user);
	const [agents, setAgents] = useState([]);
	const [agent, setAgent] = useState(null);
	const [show, setShow] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const [offset, setOffset] = useState(0);
	const [limit, setPageLimit] = useState(2000);
	const [deleting, setDeleting] = useState(false);
	const history = useHistory();

	const setCurrentAgent = (agent) => {
	    setAgent(agent);
	    setShow(true);

	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				if(role === "super"){
					const apiResponse = await getAgents(`agent/all/${offset}/${limit}`);
					const { data = [], success } = apiResponse?.data;
					if (success) {
						setAgents(data);
					}
				} else if(role === "admin"){
					const userResponse = await getUser(userId);
					const { success, data } = userResponse?.data;
					if (success) {
						localStorage.setItem("busOperator", data.user.busOperator);
						const apiResponse = await getAgents(`agent/all/operator/${data.user.busOperator}/${offset}/${limit}`);
						if (apiResponse?.data.success) {
							setAgents(apiResponse?.data.data);
						}
					}			
				}
			} catch (error) {
				alertify.error(error.message);
			} finally{
				if(agents.length > 0){
					$("#agent_table").DataTable({responsive: true, stateSave: true});			
					setTotalPages(agents.length);
					setOffset(agents.length + 1);
					setPageLimit(agents.length + 100);
				}
				setLoading(false)
			}
		};
		fetchData();
	}, [ role, userId, offset, limit ]);

  	const deleteAgent = async() => {
		try {
			setDeleting(true)
			const apiResponse = await removeAgent(agent, userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
			  alertify.success("Record removed successfully");
			  history.go(0);
			} else{
			  alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setShow(false);
			setDeleting(false);
		}
  	}

	return (
		loading ? <LoaderScreen /> :
		<>
			<table id="agent_table" className="sharearide_datatable">
				<thead>
				<tr>
					<th>ID</th>
					<th>Names</th>
					<th>Email</th>
					<th>Username</th>
					<th>Status</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
				{agents.length > 0 ? (
					agents.map((agent, index) => (
					<tr key={index}>
						<td>{agent?.agent?.id}</td>
						<td>{agent?.agent?.name}</td>
						<td>{agent?.authDetails?.email}</td>
						<td>{agent?.authDetails?.username}</td>
						<td>{agent?.agent?.status}</td>		            
						<td>
							<Link to={`/agent/edit/${agent?.agent?.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
								<span className="svg-icon svg-icon-md svg-icon-primary">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
								/>
								</span>
							</Link>
							<a 
							onClick={() => setCurrentAgent(agent?.agent.uuid, index)}
							key={index}
							className="btn btn-icon btn-light btn-hover-danger btn-sm">
								<span className="svg-icon svg-icon-md svg-icon-danger">
								<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
								</span>
							</a>
						</td>		            
					</tr>
					))
				) : (
					<tr>
					<td colSpan={6} align="center">No Agents</td>
					</tr>
				)}
				</tbody>
			</table>
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Agent</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to Delete?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)} disabled={ deleting }>
						Cancel
					</Button>
					<Button variant="primary" onClick={deleteAgent} disabled={ deleting }>
						<span>Yes</span> {deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
					</Button>
				</Modal.Footer>
			</Modal>
	    </>
	)
}