import axios from "axios";
import {API_URL, auth} from '../../../pages/constants.js';
import { persister } from "../../../../redux/store";
export const LOGIN_URL = `${API_URL}${auth}/signin`;
export const REQUEST_PASSWORD_URL = `${API_URL}${auth}/change-password/`;
export const PROFILE_URL = API_URL + "/app/v1/user/";

export function login(email, password) {
  const data = JSON.stringify({
    "email": `${email}`,
    "password": `${password}`
  });

  const config = {
    method: 'POST',
    url: `${LOGIN_URL}`,
    headers: {
      'Accept': "application/json",
      'Content-Type': "application/json",
    },
    data : data
  };

  return axios(config);
}

export function activateAccount(otp) {
  const config = {
    method: 'PUT',
    url: `${API_URL}/app/v1/uaa/otp/security/${otp}/auth`,
    headers: {
      'Accept': "application/json",
      'Content-Type': "application/json",
    }
  };
  return axios(config);
}

export function getUserByToken() {
  const config = {
    method: 'GET',
    url: `${PROFILE_URL}`,
    headers: {
      'Accept': "application/json",
      'Content-Type': "application/json",
    }
  };

  return axios(config);
}

export function requestPassword(email) {
  const config = {
    method: 'PUT',
    url: `${REQUEST_PASSWORD_URL}/${email}/email`,
    headers: {
      'Accept': "application/json",
      'Content-Type': "application/json",
    }
  };

  return axios(config);
}

export function logout() {
  persister.purge();
}

