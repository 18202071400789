import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import alertify from "alertifyjs";
import { actions } from "../../../actions";
import { LOCAL_STORAGE_CUSTOMER_DATA_KEY } from "../../../pages/constants";
import * as errorCodes from "../../../utils/errorCodes";
import Timer from "../../../utils/Timer";

function ForgotPassword({
  errorCode,
  errorMessage,
  lockStartTime,
  loading,
  closeError,
  clearLoading,
  resetPassword,
  status
}) {
  const intl = useIntl();
  const [savedCustomerData, setSavedCustomerData] = useState({});
  const [isRequested, setIsRequested] = useState(false);
  let formickActions = null;
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
  useEffect(() => {
    clearLoading();
  }, []);
  
  useEffect(() => {
    if(status){
      alertify.success("Password reset instruction sent to your email address");
      setIsRequested(true)
    }
  }, [status]);

  useEffect(() => {
    try {
      const cacheData =
        JSON.parse(
          window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY) || "{}"
        ) || {};
      setSavedCustomerData(cacheData);
      if (cacheData && cacheData.email) {
        formickActions.setFieldValue("email", cacheData.email);
      }
    } catch (e) {}
  }, []);

  const resetErrors = () => {
    window.localStorage.setItem(
      LOCAL_STORAGE_CUSTOMER_DATA_KEY,
      JSON.stringify({
        ...setSavedCustomerData,
        lockStartTime: null,
        errorCode: null,
      })
    );
    setSavedCustomerData({});
    closeError();
  };

  const handlerSubmit = (values) => {
    resetPassword(values.email);
  };

  const getInputClasses = (fieldname) => {
    if (formickActions.touched[fieldname] && formickActions.errors[fieldname]) {
      return "is-invalid";
    }

    if (formickActions.touched[fieldname] && !formickActions.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const parseErrorCode = () => {
    if (!errorCode) {
      return null;
    }
    if (errorCode === errorCodes.ATTEMPTS_EXCEEDED) {
      return (
        <FormattedMessage
          {..."ATTEMPTS_EXCEEDED"}
          values={{
            time: (
              <Timer
                timeInMinutes={59}
                initialStartTime={lockStartTime}
                onFinish={resetErrors}
              />
            ),
          }}
        />
      );
    }
    
    if (errorCode != "GENERIC_ERROR" && intl.formatMessage({id: errorCode,})) {
      return intl.formatMessage({
        id: errorCode,
      });
    }
    if (errorCode === errorCodes.GENERIC_ERROR) {
      if(errorMessage){
        return errorMessage;
      }
      return intl.formatMessage({
        id: "GENERIC_ERROR",
      });
    }
    return errorMessage;
  };

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div>
          </div>
          <Formik
            initialValues={{
              email: savedCustomerData?.email || "",
            }}
            onSubmit={(values) => handlerSubmit(values)}
            validationSchema={forgotPasswordSchema}
          >
            {(formikProps) => {
              formickActions = formikProps;
              return (
                <Form className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp" >
                  {errorCode && (!savedCustomerData || !savedCustomerData.email) && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                      <div className="alert-text font-weight-bold">{parseErrorCode()}</div>
                    </div>
                  )}
    
                  <div className="form-group fv-plugins-icon-container">
                    <input placeholder="Email" type="email" className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`} name="email" {...formickActions.getFieldProps("email")} />
                    {formickActions.touched.email && formickActions.errors.email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formickActions.errors.email}</div>
                      </div>
                    )}
                  </div>
                  <div className="form-group d-flex flex-wrap flex-center">
                    <button
                      id="kt_login_forgot_submit"
                      type="submit"
                      className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                      disabled={ loading || !formikProps.values.email || (errorCode === errorCodes.ATTEMPTS_EXCEEDED) }
                    >                      
                      <span>Submit</span>
                      {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>                   
                    <Link to="/auth">
                      <button
                        type="button"
                        id="kt_login_forgot_cancel"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        disabled={ loading }
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </Form> 
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    errorCode: state.resetPwd.errorCode,
    errorMessage: state.resetPwd.errorMessage || "",
    lockStartTime: state.resetPwd.lockStartTime,
    status: state.resetPwd.status,
    loading: state.Loading.loading,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {    
    resetPassword: (user) => {
      dispatch(actions.changePassword.requestChangePassword(user));
      dispatch(actions.loading.requestLoadingAction());
    },
    closeError: () => {
      dispatch(actions.error.cancelErrorAction());
    },
    clearLoading: () => {
      dispatch(actions.loading.completeLoadingAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
