import { call } from "redux-saga/effects";
import { events } from "../actions";
import { onRequestError, onCompleteRequest, formatRequest } from "./common";
import axios from "../../config/axiosConfig";
import { LOCAL_STORAGE_CUSTOMER_DATA_KEY, TOKEN, ID_TOKEN, } from "../pages/constants";
import { ATTEMPTS_EXCEEDED, LOGIN_FAILED } from "../utils/errorCodes";
import { logEvent } from "../utils/analyticsService";
import { Login } from "../utils/analyticsCustomEvents";
import { renewJWTToken } from "./common";

const { REACT_APP_API_VERSION } = process.env;

function postLogin(email, password) {
  return axios(
    formatRequest(
      `/api/app/uaa/${REACT_APP_API_VERSION}/signin`,
      "post",
      { email: email, password: password },
      false,
      false
    )
  );
}

export function* generateLogin(action) {
  try {
    const { data: apiResponse = {} } = yield call(
      postLogin,
      action.user,
      action.password,
    );
    logEvent(Login.START);
    let { success, data = {}, errors = [], message } = apiResponse;
    if (success) {
      logEvent(Login.SUCCESS);
      //Need to set token to authenticate the refresh token request
      window.localStorage.setItem(TOKEN, data.accessToken);
      window.localStorage.setItem(ID_TOKEN, data.idToken);
      const { data: refreshResponse } = yield renewJWTToken(
        data.userId,
        data.idToken
      );
      data = {
        accessToken: refreshResponse?.data.accessToken,
        idToken: refreshResponse?.data.idToken,
        expiresIn: refreshResponse?.data.expiresIn,
        tokenType: refreshResponse?.data.tokenType,
        email: data.email,
        isEmailVerified: data.isEmailVerified,
        userId: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        profilePicUrl: data.profilePicUrl,
        role: data.role
      };
      if (action.saveUserDataInCache) {
        const userData = {
          userId: data.userId,
          role: data.role,
          idToken: refreshResponse?.data.idToken,
          email: action.user,
        };
        window.localStorage.setItem(
          LOCAL_STORAGE_CUSTOMER_DATA_KEY,
          JSON.stringify(userData)
        );
      }
      window.localStorage.setItem(TOKEN, refreshResponse?.data.accessToken);
      window.localStorage.setItem(ID_TOKEN, refreshResponse?.data.idToken);
      yield onCompleteRequest(events.login.RECEIVED, data);
    } else {
      logEvent(Login.FAILED);
      const errorObject = errors.length > 0 ? errors[0] : {};
      const errorCode = errorObject.errorCode || "GENERIC_ERROR";
      const errorMsgFromBE = errorObject.errorMsg || message || "";
      let count = 1;      
      // temp fix ==> We are not getting unique error code from BE
      if (errorCode === LOGIN_FAILED) {
        count = errorMsgFromBE.replace(/\D/g, "").trim();
      }
      let lockStartTime = null;
      if (errorCode === ATTEMPTS_EXCEEDED) {
        lockStartTime = errorObject.waitTime;
        const userData = {
          email: action.user,
          lockStartTime,
          errorCode: ATTEMPTS_EXCEEDED,
        };
        window.localStorage.setItem(
          LOCAL_STORAGE_CUSTOMER_DATA_KEY,
          JSON.stringify(userData)
        );
      }
      window.localStorage.removeItem(TOKEN);
      window.localStorage.removeItem(ID_TOKEN);
      yield onRequestError(events.login.FAILED, errorCode, errorMsgFromBE, { count, lockStartTime, });
    }
  } catch (e) {
    logEvent(Login.FAILED);
    yield onRequestError(events.login.FAILED, "GENERIC_ERROR", e.message);
  }
}
