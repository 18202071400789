export const Role = {
    Super: 'super',
    Admin: 'admin',
    Agent: 'agent'
}

export const userRoles = {
	admins: [String(Role.Admin), String(Role.Agent)],
	agent: [String(Role.Agent)],
	super: [
		String(Role.Super),
		String(Role.Admin),
		String(Role.Agent)
	]
};