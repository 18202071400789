import { Card, CardBody, CardHeader, CardHeaderToolbar,  } from "../controls";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {API_URL, user} from '../../../app/pages/constants.js';
import { Line, Bar, Pie, Doughnut, HorizontalBar} from 'react-chartjs-2';
import {DashboardWidget} from "../widgets/mixed/DashboardWidget.js";
import Spinner from 'react-bootstrap/Spinner';
import { fetchDashboardData } from "../../../app/sagas/global";
import { useSelector } from "react-redux";
import alertify from "alertifyjs";

export function AdminDashboard() {
	const { userId, role } = useSelector((state) => state.Auth.user);
	const [currentTickets, setCurrentTicket] = useState([]);
	const [totalTickets, setTotalTicket] = useState([]);
	const [totalBuses, setTotalBuses] = useState([]);
	const [totalAgents, setTotalAgents] = useState([]);
	const [loaded, setLoaded] = useState(true);
	const [ticketsByBusData, setBusTicketsData] = useState({});
	const [ticketsRevenueByBusData, setTicketsRevenueData] = useState({});
	const [ticketsRevenueByRouteData, setTicketsRouteRevenueData] = useState({});
	const [weeklyData, setDayWiseTickets] = useState({});
	const [dataByMonths, setMonthWiseTickets] = useState({});
	const colors = [getRandomColor(),getRandomColor(),getRandomColor()];
	
	function getRandomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
		  color += letters[Math.floor(Math.random() * 10)];
		}
		return color;
	}

	useEffect(() => {
		const fetchData = async () => {
			try{
				axios.get(`${API_URL}${user}/${userId}`).then( (response) => {
					const { success, errors = [], data, message } = response?.data;
					if(success){
						localStorage.setItem("busOperator", data.user.busOperator);
					}
				});
				const response = await fetchDashboardData(`${userId}/admin`);				
				const { success, errors = [], data, message } = response?.data;
				if(success){
					setCurrentTicket(data?.currentTotalTicketsSold);
					setTotalTicket(data?.totalTicketsSold);
					setTotalBuses(data?.totalBuses);
					setTotalAgents(data?.totalAgents);
					if(data.busTickets !== undefined && data.busTickets !== null){
						if(data.busTickets.length > 0){
							setBusTicketsData({
								labels: data.busTickets.map(info => info.bus),
								datasets: [{
									label: '# of tickets',
									data: data.busTickets.map(info => info.tickets),
									backgroundColor: [
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor()
									],
									hoverBackgroundColor: [
										'rgba(75, 192, 192, 1)',
										'rgba(54, 162, 235, 1)',
										'rgba(255, 109, 64, 1)',
										'rgba(75, 109, 192, 1)',
										'rgba(109, 192, 192, 1)'
									]
								}]
							});
							setTicketsRevenueData({
								labels: data.busTickets.map(info => info.bus),
								datasets: [{
									label: 'Sales (BWP)',
									data: data.busTickets.map(info => info.revenue),
									backgroundColor: [
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor()
									],
									hoverBackgroundColor: [
										'#FF6384',
										'#36A2EB',
										'#FFCE56',
										'#36AE56',
										'#36A384'
									]
								}]
							});
						}
					}
								
					if(data.routeTickets !== undefined && data.routeTickets !== null){
						if(data.routeTickets.length > 0){
							setTicketsRouteRevenueData({
								labels: data.routeTickets.map(info => info.routeName),
								datasets: [{
									label: '# of Tickets',
									data: data.routeTickets.map(info => info.numberOfTickets),
									backgroundColor: [
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor()
									],
									hoverBackgroundColor: [
										'#FF6384',
										'#36A2EB',
										'#FFCE56',
										'#36AE56',
										'#FFC2EB'
									]
								}]
							});
						}
					}
								
					if(data.lastSevenDaysBookingResult !== undefined && data.lastSevenDaysBookingResult !== null){
						if(data.lastSevenDaysBookingResult.length > 0){
							setDayWiseTickets({
								labels: data.lastSevenDaysBookingResult.map(info => info.Day),
								datasets: [{
									barPercentage: 1,
									barThickness: 50,
									maxBarThickness:50,
									minBarLength: 0,
									data: data.lastSevenDaysBookingResult.map(info => info.total),
									backgroundColor: [
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor(),
										getRandomColor()
									],
								}]
							});
						}
					}
								
					if(data.annualStatistics.allTickets !== undefined && data.annualStatistics.allTickets !== null){
						if(data.annualStatistics.allTickets.length > 0){
							setMonthWiseTickets({
								labels: data.annualStatistics.allTickets.map(info => info.Month),
								datasets: [
									{
										label: 'All',
										fill: false,
										lineTension: 0.1,
										backgroundColor: colors[0],
										borderColor: colors[0],
										borderCapStyle: 'butt',
										borderDash: [],
										borderDashOffset: 0.0,
										borderJoinStyle: 'miter',
										pointBorderColor: colors[0],
										pointBackgroundColor: colors[0],
										pointBorderWidth: 1,
										pointHoverRadius: 5,
										pointHoverBackgroundColor: colors[0],
										pointHoverBorderColor: colors[0],
										pointHoverBorderWidth: 2,
										pointRadius: 1,
										pointHitRadius: 10,
										data: data.annualStatistics.allTickets.map(info => info.total),
									},
									{
										label: 'Confirmed',
										fill: false,
										lineTension: 0.1,
										backgroundColor: colors[1],
										borderColor: colors[1],
										borderCapStyle: 'butt',
										borderDash: [],
										borderDashOffset: 0.0,
										borderJoinStyle: 'miter',
										pointBorderColor: colors[1],
										pointBackgroundColor: colors[1],
										pointBorderWidth: 1,
										pointHoverRadius: 5,
										pointHoverBackgroundColor: colors[1],
										pointHoverBorderColor: colors[1],
										pointHoverBorderWidth: 2,
										pointRadius: 1,
										pointHitRadius: 10,
										data: data.annualStatistics.usedTickets.map(info => info.total),
									},              
									{
										label: 'Cancelled',
										fill: false,
										lineTension: 0.1,
										backgroundColor: colors[2],
										borderColor: colors[2],
										borderCapStyle: 'butt',
										borderDash: [],
										borderDashOffset: 0.0,
										borderJoinStyle: 'miter',
										pointBorderColor: colors[2],
										pointBackgroundColor: colors[2],
										pointBorderWidth: 1,
										pointHoverRadius: 5,
										pointHoverBackgroundColor: colors[2],
										pointHoverBorderColor: colors[2],
										pointHoverBorderWidth: 2,
										pointRadius: 1,
										pointHitRadius: 10,
										data: data.annualStatistics.cancelledTickets.map(info => info.total)
									},              
									{
										label: 'Active',
										fill: false,
										lineTension: 0.1,
										backgroundColor: getRandomColor(),
										borderColor: getRandomColor(),
										borderCapStyle: 'butt',
										borderDash: [],
										borderDashOffset: 0.0,
										borderJoinStyle: 'miter',
										pointBorderColor: getRandomColor(),
										pointBackgroundColor: getRandomColor(),
										pointBorderWidth: 1,
										pointHoverRadius: 5,
										pointHoverBackgroundColor: getRandomColor(),
										pointHoverBorderColor: getRandomColor(),
										pointHoverBorderWidth: 2,
										pointRadius: 1,
										pointHitRadius: 10,
										data: data.annualStatistics.activeTickets.map(info => info.total)
									}
								]
							});
						}
					}
				}
			} catch (error) {
				alertify.error(error.message);
			} finally {
				setLoaded(false);
			} 
		}
		fetchData();
	}, []);

    return (
		loaded ? 
			<div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
				<div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
					<Spinner animation="grow" variant='warning' />
				</div>
			</div>
		: <>
			<Row>
				<Col md={3}>
					<DashboardWidget  
						className="gutter-b"
						symbolShape="circle"
						baseColor="danger"
						mainTitle="Tickets Sold Today"
						value={currentTickets}
						icon="Shopping/Ticket.svg"
					/>
				</Col>
				<Col md={3}>
					<DashboardWidget  
						className="gutter-b"
						symbolShape="circle"
						baseColor="primary"
						mainTitle="Total Tickets Sold"
						value={totalTickets}
						icon="Shopping/Money.svg"
					/>
				</Col>
				<Col md={3}>
					<DashboardWidget  
						className="gutter-b"
						symbolShape="circle"
						baseColor="warning"
						mainTitle="Total Buses"
						value={totalBuses}
						icon="Custom/Bus.svg"
					/>
				</Col>
				<Col md={3}>
					<DashboardWidget  
						className="gutter-b"
						symbolShape="circle"
						baseColor="success"
						mainTitle="Total Agents"
						value={totalAgents}
						icon="General/User.svg"
					/>
				</Col>		
			</Row>

			<Row>
				<Col md={4}>
					<Card>
						<CardHeader title="Tickets Sold Per Bus">
							<CardHeaderToolbar>
							
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<Pie data={ticketsByBusData} width={400} height={400}/>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardHeader title="Ticket Sold By Route">
							<CardHeaderToolbar>
							
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<Doughnut data={ticketsRevenueByRouteData} width={400} height={400}/>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardHeader title="Ticket Revenue Per Bus">
							<CardHeaderToolbar>
							
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<HorizontalBar data={ticketsRevenueByBusData} width={400} height={400}/>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
			<Col md={6}>
				<Card>
	      			<CardHeader title="Tickets Per Day (Last 7 Days)"/>
				    <CardBody>
		            	<Bar data={weeklyData} width={400} height={400} options={{
            				responsive: true, maintainAspectRatio: true, legend: { display: false, }, scales: { yAxes: [{	stacked: true }]}
        				}}/>
		            </CardBody>
		        </Card>
	        </Col>
			<Col md={6}>
				<Card>
	      			<CardHeader title="Tickets Per Month (Last 12 Months)"/>
				    <CardBody>
		            	<Line data={dataByMonths} width={400} height={400} />
		            </CardBody>
		        </Card>
	        </Col>
		</Row>
    	</>
	);
}
