import React, {useEffect, useState } from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import { LoaderScreen } from '../../modules/Loader';
import { getUsers, } from "../../../app/sagas/userManagement";

export function UserList(props){

	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [offset, setOffset] = useState(0);
	const [limit, setPageLimit] = useState(2000);
	const [totalPages, setTotalPages] = useState(0);

	useEffect( () => {
		const fetchData = async () => {
			try {
				const apiResponse = await getUsers(offset, limit);
				const { data = [], success } = apiResponse?.data;
				if (success) {
					setUsers(data);
				}
			} catch (error) {
				alertify.error(error.message);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		if(users.length > 0){
			$("#user_table").DataTable({responsive: true, stateSave: true});			
			setTotalPages(users.length);
			setOffset(users.length + 1);
			setPageLimit(users.length + 100);
		}
		setLoading(false)
	}, [users]);

	return (
		loading ? <LoaderScreen /> :
		<>
			<table id="user_table" className="sharearide_datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Phone</th>
						<th>Role</th>
						<th>Username</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users.length > 0 ? (
						users.map((user) => (
							<tr key={user.user.id}>
								<td>{user.user.id}</td>
								<td>{user?.user?.fname}</td>
								<td>{user?.user?.lname}</td>
								<td>{user?.user?.phone}</td>
								<td>{user?.user?.role}</td>
								<td>{user?.authDetails?.username}</td>
								<td>{user?.user?.status}</td>
								<td>
									<Link to={`/user/detail/${user.user.uuid}/edit`} className="btn btn-icon btn-light btn-hover-primary  btn-xs mx-2">
										<span className="svg-icon svg-icon-md svg-icon-primary">
										<SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
										</span>
									</Link>
									<Link to={`/user/detail/${user.user.uuid}/view`} className="btn btn-icon btn-light btn-hover-success  btn-xs mx-2">
										<span className="svg-icon svg-icon-md svg-icon-success ">
										<SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
										</span>
									</Link>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align="center">No User</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	)
}