import { combineReducers } from "redux";
import Error from "./Error";
import Loading from "./Loading";
import Auth from "./Auth";
import * as actionTypes from "../actions/actionTypes";
import { resetPassword, } from "./ResetPassword";
import routeManagement from "./routeManagement";
const appReducer = combineReducers({
  Auth,
  Error,
  Loading,
  resetPwd: resetPassword,
  routeManagement,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === actionTypes.DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
