import { useState, useEffect } from "react";
import { addMinutes } from "date-fns";

const Timer = ({
  timeInMinutes = 60,
  initialStartTime = new Date(),
  onFinish,
}) => {
  const startTime = addMinutes(initialStartTime, timeInMinutes).getTime()
  const date = new Date();
  const timerTime = startTime - date.getTime();
  const [timer, setTimer] = useState(timerTime > 0 ? timerTime : 0);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (timer > 0) {
        const date = new Date();
        const timerTime = startTime - date.getTime();
        if (timerTime > 0) {
          setTimer(timerTime);
        }
      }
    }, 1000);
    // clear it if time is less than a second
    if (timer <= 999) {
      if (onFinish) {
        onFinish();
      }
      clearInterval(myInterval);
    }

    return () => {
      clearInterval(myInterval);
    };
  }, [timer]);

  const parseTimerTime = () => {
    const minutes = Math.floor((timer / 1000 / 60 / 60) * 60);
    let seconds = Math.floor(((timer / 1000 / 60 / 60) * 60 - minutes) * 60);
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  };

  return parseTimerTime();
};

export default Timer;
