import React, { } from "react";
import RouteList from "./RouteList";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../../_metronic/_partials/controls";

export function Routes() {
  return (
  	<Card>
      <CardHeader title="Route List">
        <CardHeaderToolbar>           
          <Link to="/route/create" className="btn btn-primary">Add New</Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>        
        <div className="mt-5"><RouteList /></div>
      </CardBody>
    </Card>
  );
}