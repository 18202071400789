import React, { useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import Nav from 'react-bootstrap/Nav';
import TabPane from 'react-bootstrap/TabPane';
import TabContent from 'react-bootstrap/TabContent';
import TabContainer from 'react-bootstrap/TabContainer';
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { createOperator } from "../../../app/sagas/userManagement";
import { useForm } from 'react-hook-form';

export function CreatePartner(props) {

  const [loading, setLoading] = useState(false);
  const { userId } = useSelector((state) => state.Auth.user);
  const name = useRef();
  const username = useRef();  
  const password = useRef(); 
  const email = useRef(); 
  const accountStatus = useRef(); 
  const phone = useRef();
  const accountPhone = useRef();
  const lname = useRef(); 
  const fname = useRef();
  const status = useRef();
  const [image, setImage] = useState('');
  const { register, handleSubmit, formState: { errors }, } = useForm();

  const createPartnerHandler = async(data) => { 
    setLoading(true);
    try {
      const apiResponse = await createOperator( {
        "operator":{
          "id": null,
          "uuid": null,
          "name": data.name,
          "contact": data.contactNumber,
          "image": data.image || null,
          "order": 1,
          "status": data.status,
          "createdBy": null,
          "updatedBy": null,
          "deleted": false
        },
        "administratorAccount":{
          "user":{
            "id": null,
            "uuid": null,
            "fname": data.firstName,
            "lname": data.lastName,
            "phone": data.userNumber,
            "image": null,
            "role": "admin",
            "status": data.accountStatus,
          },
          "login":{
            "username": data.username,
            "email": data.email,
            "password": data.password,
          }
        }
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record added successfully");
        props.history.push("/partner/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }    
  }

  const handleImage = (e) =>{
    var reader = new FileReader(); 
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  return (
    loading ? <LoaderScreen />:
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Add New Partner"/>
          <CardBody>
            <div className="mt-5">
              <Form onSubmit={handleSubmit(createPartnerHandler)}>
                <TabContainer id="left-tabs-example" defaultActiveKey="partner_details">
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item> <Nav.Link eventKey="partner_details">Partner Information</Nav.Link></Nav.Item>
                        <Nav.Item> <Nav.Link eventKey="admin_account">Administrator Account</Nav.Link> </Nav.Item>
                        <Nav.Item> <Nav.Link eventKey="login_information">Login Details</Nav.Link> </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      <TabContent>
                        <TabPane eventKey="partner_details">
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label>Name</Form.Label>
                              <Form.Control ref={name} placeholder="Enter name" {...register('name', { required: true })} style={errors.name && { borderColor:"red"}}/>
                              {errors.name && <p style={{color: "red"}}>Field Required</p>}
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>Contact Number(+267...)</Form.Label>
                              <Form.Control type="tel" ref={phone} placeholder="Enter contact number" {...register('contactNumber', { 
                                required: true, pattern: /^[0|+][0-9]{3}?[7]{1}[1-7]{1}[0-9]{3}[0-9]{3}/i })} style={errors.contactNumber && { borderColor:"red"}}/>
                              {errors.contactNumber && errors.contactNumber.type === "required" && (<p style={{color: "red"}}>Field Required</p>)}
                              { errors.contactNumber && errors.contactNumber.type === "pattern" && (<p style={{color: "red"}}>Wrong mobile number format</p>)}
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label>Image</Form.Label>
                              <Row>
                                <Col> <Form.Control type="file" onChange={handleImage} {...register('image')}/></Col>
                                <Col> {image !== ''? <img width='100' src={image} alt=""/> : ''} </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>Status</Form.Label>
                                <Form.Control as="select" ref={status} {...register('status', { required: true })} style={errors.status && { borderColor:"red"}}>
                                  <option value="true">Active</option>
                                  <option value="false">In-Active</option>
                                </Form.Control>                              
                                {errors.status && <p style={{color: "red"}}>Field Required</p>}
                            </Form.Group>
                          </Form.Row>
                        </TabPane>
                        <TabPane eventKey="admin_account">
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label>First Name</Form.Label>
                              <Form.Control ref={fname} placeholder="Enter first name" {...register('firstName', { required: true })} style={errors.firstName && { borderColor:"red"}}/>
                              {errors.firstName && <p style={{color: "red"}}>Field Required</p>}
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control ref={lname} placeholder="Enter last name" {...register('lastName')} style={errors.lastName && { borderColor:"red"}}/>
                              {errors.lastName && <p style={{color: "red"}}>Field Required</p>}
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label>Contact Number(+267...)</Form.Label>
                              <Form.Control type="tel" ref={accountPhone} placeholder="Enter contact number" {...register('userNumber', { 
                                required: true, pattern: /^[0|+][0-9]{3}?[7]{1}[1-7]{1}[0-9]{3}[0-9]{3}/i })} style={errors.userNumber && { borderColor:"red"}}/>
                                {errors.userNumber && errors.userNumber.type === "required" && (<p style={{color: "red"}}>Field Required</p>)}
                                { errors.userNumber && errors.userNumber.type === "pattern" && (<p style={{color: "red"}}>Wrong mobile number format</p>)}
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>Status</Form.Label>
                              <Form.Control as="select" ref={accountStatus} {...register('accountStatus', { required: true })} style={errors.accountStatus && { borderColor:"red"}}>
                                <option value="ACTIVE">Active</option>
                                <option value="INACTIVE">In-Active</option>
                              </Form.Control>
                              {errors.accountStatus && <p style={{color: "red"}}>Field Required</p>}
                            </Form.Group>
                          </Form.Row>                        
                        </TabPane>
                        <TabPane eventKey="login_information">
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label>Username</Form.Label>
                              <Form.Control ref={username} placeholder="Enter username" {...register('username', { required: true })} style={errors.username && { borderColor:"red"}}/>
                              {errors.username && <p style={{color: "red"}}>Field Required</p>}
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>Email</Form.Label>
                              <Form.Control ref={email} placeholder="Enter email" {...register('email', { required: true })} style={errors.email && { borderColor:"red"}}/>
                              {errors.email && <p style={{color: "red"}}>Field Required</p>}
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>Password</Form.Label>
                              <Form.Control type="password" ref={password} placeholder="Enter password" {...register('password', { required: true })} style={errors.password && { borderColor:"red"}}/>
                              {errors.password && <p style={{color: "red"}}>Field Required</p>}
                            </Form.Group>
                          </Form.Row>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </TabContainer>
			          <Button variant="primary" className="float-right" type="submit">Save</Button>
			        </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
   </Row>
  );
}