import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";

export function MixedWidget4({ className, chartColor = "danger", data}) {

	const [dashboardData, setDashboardData] = useState({});

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);
  
  useEffect(() => {
    setDashboardData(data)
    if(data?.result > 0 ){
      const element = document.getElementById("kt_mixed_widget_4_chart");
      if (!element) {
        return;
      }
      const options = getChartOption(layoutProps, dashboardData);
      const chart = new ApexCharts(element, options);
      chart.render();
      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [data]);

  return (
    <>
      <div className={`card card-custom bg-radial-gradient-danger ${className}`} >
        <div className="card-header border-0 pt-5">
          <h3 className="card-title font-weight-bolder text-white">
            Bus Tickets Revenue
          </h3>
        </div>
        <div className="card-body d-flex flex-column p-0">
          <div id="kt_mixed_widget_4_chart" data-color={chartColor} style={{ height: "170px", minHeight: "170px" }} />
          <div className="card-spacer bg-white card-rounded flex-grow-1">
            <div className="row m-0">
              <div className="col px-8 py-6 mr-8">
                <div className="font-size-sm text-muted font-weight-bold">
                  Current Bookings
                </div>
                <div className="font-size-h4 font-weight-bolder">{dashboardData?.dailyTickets || 0}</div>
              </div>
              <div className="col px-8 py-6">
                <div className="font-size-sm text-muted font-weight-bold">
                  Last 7 days Bookings
                </div>
                <div className="font-size-h4 font-weight-bolder">{dashboardData?.weeklyTickets || 0}</div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col px-8 py-6 mr-8">
                <div className="font-size-sm text-muted font-weight-bold">
                  Monthly Bookings
                </div>
                <div className="font-size-h4 font-weight-bolder">{dashboardData?.monthlyTickets || 0}</div>
              </div>
              <div className="col px-8 py-6">
                <div className="font-size-sm text-muted font-weight-bold">
                  All Bookings
                </div>
                <div className="font-size-h4 font-weight-bolder">{data.annualTickets || 0}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function getChartOption(layoutProps, data) {
  const options = {
    series: [
      {
        name: "Number Of Tickets",
        data: data.result.numberOfTickets.map(info => info.total),
      },
      {
        name: "Revenue BWP ",
        data: data.result.revenue.map(info => info.revenue),
      },
    ],
    chart: {
      type: "bar",
      height: "180px",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: ["30%"],
        endingShape: "rounded",
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    fill: {
      type: ["solid", "solid"],
      opacity: [0.25, 1],
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return val;
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ["#ffffff", "#ffffff"],
    grid: {
      borderColor: layoutProps.colorsGrayGray200,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 20,
        right: 20,
      },
    },
  };
  return options;
}
